import {
  type Channel,
  type Event,
  type Message,
  type MessageResponse,
  type UnknownType,
} from 'stream-chat';

import logger from '../../logger/logger';
import {
  ChannelType,
  ChatUnreadMessagesCounterDOMId,
  type Recipient,
  StreamChatEventType,
  TypingIndicatorConfig,
} from './common';

const log = logger.scoped('chat');

export type SCEventType = { [type: string]: StreamChatEventType };
export type SCUserType = {
  id: string;
  username: string;
  color: string;
};

export type SCMessageExtensions = {
  venueId: string;
  channelType: ChannelType;
  clientRefId: string;
  senderClientId: string;
  senderRid: string;
  receiverClientId?: string; // only for private channel
  receiverRid: string; // only for private channel
};
export type SCMessageType = {
  id: string;
  text: string;
  user: SCUserType;
  mentioned_users?: string[] | SCUserType[];
  extensions?: SCMessageExtensions;
  type?: string;
  created_at: string;

  // local message attributes
  cid?: string;
  local?: boolean;
  recipient?: Recipient;
};

export type SCMessage = Message<UnknownType, SCMessageType, SCUserType>;

export type SCChannel = Channel<
  UnknownType,
  UnknownType,
  string,
  SCEventType,
  SCMessageType,
  UnknownType,
  SCUserType
>;

export type SCEvent = Event<
  UnknownType,
  UnknownType,
  string,
  SCEventType,
  SCMessageType,
  UnknownType,
  SCUserType
>;

export type SCMessageResponse = MessageResponse<
  UnknownType,
  UnknownType,
  string,
  SCMessageType,
  UnknownType,
  SCUserType
>;

type LocalMessage = {
  rid: string;
  payload: SCMessage;
};

interface TypingState {
  lastUpdatedTime: number;
  lastTypingStartEventSentTime: number;
  typingStopTimerId?: ReturnType<typeof setTimeout>;
}

export interface RemoteTypingUser {
  lastUpdatedTime: number;
  user: SCUserType;
}

function getDefaultTypeState(): TypingState {
  return {
    lastUpdatedTime: 0,
    lastTypingStartEventSentTime: 0,
  };
}

// eslint-disable-next-line import/no-default-export
export default class ChatService {
  private channels: Record<string, SCChannel>;
  private localTypingStates: Record<string, TypingState>;
  private messageQueue: (LocalMessage | null)[];
  private stop: boolean;
  private round: number;
  unreadCount: number;
  isUserScrolling: boolean;
  scrollToBottom?: () => void;
  scrollToBottomTimerId?: ReturnType<typeof setTimeout>;
  lastMessageTimestamp?: number;

  constructor() {
    this.channels = {};
    this.localTypingStates = {};
    this.messageQueue = [];
    this.stop = false;
    this.round = 0;
    this.unreadCount = 0;
    this.isUserScrolling = false;
  }

  private compactMessageQueue(): void {
    const compactedMessageQueue = [];
    for (let i = 0; i < this.messageQueue.length; i++) {
      if (this.messageQueue[i]) {
        compactedMessageQueue.push(this.messageQueue[i]);
      }
    }
    this.messageQueue = compactedMessageQueue;
  }

  startWorkers(): void {
    this.stop = false;
    this.startAsyncMessageWorker();
  }

  startAsyncMessageWorker(): void {
    log.debug('AsyncMessageWorker - start');
    const run = () => {
      this.round += 1;
      const len = this.messageQueue.length;
      if (len > 0) {
        log.debug(
          `AsyncMessageWorker - #${this.round} round, pending messages: ${len}`
        );
      }
      for (let i = 0; i < len; i++) {
        const m = this.messageQueue[i];
        if (!m) {
          continue;
        }
        const channel = this.getChannel(m.rid);
        if (channel && channel.initialized) {
          log.debug('AsyncMessageWorker - sendMessage', {
            rid: m.rid,
          });
          channel.sendMessage(m.payload);
          this.messageQueue[i] = null;
        }
      }
      this.compactMessageQueue();
      if (!this.stop) {
        setTimeout(run, 1000);
      }
    };
    setTimeout(run, 1000);
  }

  stopWorkers(): void {
    this.stop = false;
    log.debug('AsyncMessageWorker - stop');
    log.debug('RemoteTypingUserCheckWorker - stop');
  }

  stopAsyncMessageWorker(): void {
    this.stop = true;
    log.debug('AsyncMessageWorker - stop');
  }

  addAsyncMessage(m: LocalMessage): void {
    this.messageQueue.push(m);
  }

  addChannel(rid: string, channel: SCChannel): void {
    this.channels[rid] = channel;
  }

  getChannel(rid: string): SCChannel {
    return this.channels[rid];
  }

  delete(rid: string): void {
    delete this.channels[rid];
  }

  incrUnreadCount(increment = 1): void {
    this.unreadCount += increment;
    const el = document.getElementById(ChatUnreadMessagesCounterDOMId);
    if (el) {
      el.innerText = `${this.unreadCount}`;
      el.style.display = 'flex';
    }
  }

  resetUnreadCount(): void {
    this.unreadCount = 0;
    const el = document.getElementById(ChatUnreadMessagesCounterDOMId);
    if (el) {
      el.innerText = `${this.unreadCount}`;
      el.style.display = 'none';
    }
  }

  getLocalTypingState(rid: string): TypingState {
    return this.localTypingStates[rid] || getDefaultTypeState();
  }

  typing(rid: string): void {
    const channel = this.getChannel(rid);
    if (!channel || !channel.initialized) {
      return;
    }
    const channelType = channel._data?.['channelType'];
    if (channelType === ChannelType.Private) return;
    const typingState = this.getLocalTypingState(rid);
    const now = new Date().getTime();
    const lastTypingStartEventSentTime =
      typingState.lastTypingStartEventSentTime || 0;
    // Send Typing Start event every ${typingStartEventInterval} seconds
    if (
      now - lastTypingStartEventSentTime >
      TypingIndicatorConfig.typingStartSendInterval
    ) {
      channel.sendEvent({
        type: StreamChatEventType.TypingStart,
      });
      typingState.lastTypingStartEventSentTime = now;
      log.debug('TypingStart - sent', { rid: rid });
    }
    typingState.lastUpdatedTime = now;
    // For each typing, we clear and reschedule the typing stop check.
    if (typingState.typingStopTimerId) {
      clearTimeout(typingState.typingStopTimerId);
    }
    typingState.typingStopTimerId = this._scheduleTypingStopEvent(rid);
    this.localTypingStates[rid] = typingState;
  }

  _scheduleTypingStopEvent(rid: string): ReturnType<typeof setTimeout> {
    return setTimeout(() => {
      const channel = this.getChannel(rid);
      if (!channel || !channel.initialized) {
        log.debug('TypingStop - channel not found', { rid: rid });
        return;
      }
      const typingState = this.getLocalTypingState(rid);
      const now = new Date().getTime();
      if (
        now - typingState.lastUpdatedTime >
        TypingIndicatorConfig.typingStopWaitInterval
      ) {
        channel.sendEvent({
          type: StreamChatEventType.TypingStop,
        });
        typingState.lastTypingStartEventSentTime = 0;
        log.debug('TypingStop - sent', { rid: rid });
      }
    }, TypingIndicatorConfig.typingStopWaitInterval + TypingIndicatorConfig.buffer);
  }

  scheduleScrollToBottom(ms = 0, resetScrollingState = true): void {
    this.cancelScrollToBottom();
    this.scrollToBottomTimerId = setTimeout(() => {
      if (this.scrollToBottom) {
        if (resetScrollingState) {
          this.isUserScrolling = false;
        }
        this.scrollToBottom();
      }
    }, ms);
  }

  cancelScrollToBottom(): void {
    if (this.scrollToBottomTimerId) {
      clearTimeout(this.scrollToBottomTimerId);
    }
  }
}
