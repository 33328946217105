import { plural } from 'pluralize';

import { type GameLike } from '../../../types/game';
import { HeaderLayout, SearchBarV2 } from '../../Header';
import { HeaderAvatar } from '../../HeaderAvatar';
import { LPLogo } from '../../icons/LPLogo';
import { type GameLikeContext } from '../GameCenter';
import {
  type GameLibraryType,
  useCloseGameLibrary,
  useGameLibrary,
  useUpdateGameLibrary,
} from './GameLibraryContext';
import { GameLibraryTypeLabelMap } from './types';

const MenuItems: GameLibraryType[] = ['game', 'gamePack'];

function GameLibraryHeaderMenus(): JSX.Element {
  const library = useGameLibrary();
  const updateGameLibrary = useUpdateGameLibrary();

  return (
    <div className='flex items-center justify-start gap-10 xl:gap-16 2xl:gap-20'>
      {MenuItems.map((item) => {
        return (
          <button
            type='button'
            key={item}
            className={`btn rounded-none tracking-widest pb-1 text-white ${
              library?.type === item ? 'font-bold border-b-2 border-white' : ''
            }`}
            onClick={() => updateGameLibrary({ type: item })}
          >
            {plural(GameLibraryTypeLabelMap[item])}
          </button>
        );
      })}
    </div>
  );
}

function GameLibrarySearchBar<T extends GameLike>({
  ctx,
}: {
  ctx: GameLikeContext<T>;
}): JSX.Element {
  const handleSearchChanged = (q: string) => {
    if (!ctx.embed) return;
    const trimmed = q.trim();
    const params = new URLSearchParams(ctx.embedCtx?.search);
    params.set('q', trimmed);
    ctx.updateEmbedCtx({ search: params.toString() });
  };

  return <SearchBarV2 onChange={handleSearchChanged} />;
}

function CloseButton(): JSX.Element {
  const closeGameLibrary = useCloseGameLibrary();
  return (
    <button
      type='button'
      className='btn-secondary w-34 h-10 flex flex-row justify-center items-center'
      onClick={closeGameLibrary}
    >
      Cancel
    </button>
  );
}

export function GameLibraryHeader<T extends GameLike>({
  ctx,
  menus,
  search,
  avatar,
}: {
  ctx: GameLikeContext<T>;
  menus?: boolean;
  search?: boolean;
  avatar?: boolean;
}): JSX.Element {
  const left = (
    <>
      <LPLogo />
      {menus && <GameLibraryHeaderMenus />}
    </>
  );

  const right = (
    <>
      {search && <GameLibrarySearchBar ctx={ctx} />}
      <CloseButton />
      {avatar && <HeaderAvatar />}
    </>
  );

  return <HeaderLayout fill left={left} right={right} />;
}
