import { useEffect, useMemo, useState } from 'react';
import { match } from 'ts-pattern';
import { useThrottledCallback } from 'use-debounce';

import { type ClientId, ProfileIndex } from '@lp-lib/crowd-frames-schema';

import { useEmojiBoardAnalytics } from '../../analytics/emojiBoard';
import { useInstance } from '../../hooks/useInstance';
import { useBrandPublic } from '../Brand';
import {
  type Classes,
  StagedTailwindTransition,
  type TailwindTransitionStage,
} from '../common/TailwindTransition';
import { CrowdFramesRenderlessAvatar } from '../CrowdFrames';
import {
  useFetchGameSessionGamePack,
  useGameSessionBlock,
  useOndGameState,
} from '../Game/hooks';
import { ArrowLeftIcon, ArrowRightIcon } from '../icons/Arrows';
import { LayoutAnchor } from '../LayoutAnchors/LayoutAnchors';
import { usePixelFxSceneControl } from '../PixelFx/PixelFxSceneProvider';
import { useStreamSessionId } from '../Session';
import { useUser } from '../UserContext';
import { useMyClientId } from '../Venue';
import { type EmojiButtonState } from './type';

const PRESET_EMOJIS = [
  '😀',
  '❤️',
  '🤪',
  '😭',
  '🤩',
  '💩',
  '👏',
  '🏆',
  '🤣',
  '🥳',
  '👍',
  '👎',
  '💯',
  '🔥',
];

function Collapsed(props: {
  emoji: string;
  onSend: () => void;
  onExpand: () => void;
  disabled?: boolean;
}): JSX.Element {
  const { emoji, onSend, onExpand, disabled } = props;

  return (
    <div className='relative flex items-center'>
      <LayoutAnchor
        id={`emojis-collapsed-button-anchor`}
        className='absolute w-full h-full -z-1'
        layoutReportDelayMs={200}
      />

      <button
        type='button'
        onClick={onExpand}
        disabled={disabled}
        className='btn w-4.5 h-6.5 bg-modal hover:bg-lp-gray-003 text-white 
          border border-secondary rounded-l-lg rounded-r-none -mr-0.25
          flex justify-center items-center
        '
      >
        <ArrowLeftIcon className='fill-current w-2.5 h-2.5' />
      </button>

      <button
        type='button'
        onClick={onSend}
        disabled={disabled}
        className='btn w-10 h-10 bg-emoji-button p-0.5 rounded-xl text-2xl active:text-xl transition-all duration-100'
      >
        <div
          className='w-full h-full bg-lp-black-004 hover:bg-lp-gray-003 rounded-xl 
            flex justify-center items-center select-none
          '
        >
          {emoji}
        </div>
      </button>
    </div>
  );
}

function Transition(props: {
  emojis: string[];
  stage: 'expanding' | 'collapsing';
  onComplete: () => void;
}) {
  const stages = useMemo<TailwindTransitionStage<Classes>[]>(() => {
    // const expendedWidth =
    //   props.emojis.length * 24 + (props.emojis.length - 1) * 10 + 32;
    const results = [
      { classes: 'w-10 h-9.5 p-0.5' },
      { classes: `w-[498px] h-10 p-0` },
    ];
    return props.stage === 'expanding' ? results : results.reverse();
  }, [props.stage]);

  return (
    <StagedTailwindTransition stages={stages} onComplete={props.onComplete}>
      {(ref, initial) => (
        <div
          ref={ref}
          className={`transform transition-all duration-300 rounded-xl bg-emoji-button ${initial}`}
        >
          <div className='w-full h-full bg-modal rounded-xl'></div>
        </div>
      )}
    </StagedTailwindTransition>
  );
}

function Expanded(props: {
  emojis: string[];
  onSend: (emoji: string) => void;
  onCollapse: () => void;
}): JSX.Element {
  const { emojis, onSend, onCollapse } = props;

  return (
    <div className='relative flex items-center'>
      <LayoutAnchor
        id={`emojis-expanded-board-anchor`}
        className='absolute w-full h-full -z-1'
        layoutReportDelayMs={200}
      />

      <button
        type='button'
        onClick={onCollapse}
        className='btn w-4.5 h-6.5 bg-modal hover:bg-lp-gray-003 text-white 
          border border-secondary rounded-l-lg rounded-r-none -mr-0.25
          flex justify-center items-center
        '
      >
        <ArrowRightIcon className='fill-current w-2.5 h-2.5' />
      </button>

      <div
        className='bg-modal rounded-xl px-4 py-2
          flex justify-center items-center gap-2.5
        '
      >
        {emojis.map((emoji) => (
          <button
            type='button'
            key={emoji}
            className='btn w-6 h-6 select-none
              flex justify-center items-center
              text-2xl active:text-xl transition-all duration-100
            '
            onClick={() => onSend(emoji)}
          >
            {emoji}
          </button>
        ))}
      </div>
    </div>
  );
}

function useAnalyticsSharedProperties(props: {
  buttonState: EmojiButtonState;
}) {
  const { buttonState } = props;
  const block = useGameSessionBlock();
  const pack = useFetchGameSessionGamePack();
  const sessionId = useStreamSessionId();
  const ondGameState = useOndGameState();
  const { data: brand } = useBrandPublic(block?.brandId);

  return useMemo(
    () => ({
      ondGameState,
      blockId: block?.id,
      blockType: block?.type,
      sessionId,
      packId: pack?.id,
      packName: pack?.name,
      packVersion: pack?.version,
      gameType: pack?.detailSettings?.gameType,
      buttonState,
      brandId: block?.brandId,
      brandName: brand?.name,
    }),
    [
      block?.brandId,
      block?.id,
      block?.type,
      brand?.name,
      buttonState,
      ondGameState,
      pack?.detailSettings?.gameType,
      pack?.id,
      pack?.name,
      pack?.version,
      sessionId,
    ]
  );
}

export function EmojiTriggerButton(props: { onSend: () => void }): JSX.Element {
  const user = useUser();
  const myClientId = useMyClientId();
  const api = usePixelFxSceneControl('emojis');
  const analytics = useEmojiBoardAnalytics();

  const [buttonState, setButtonState] = useState<EmojiButtonState>('collapsed');
  const [defaultEmoji, setDefaultEmoji] = useState(PRESET_EMOJIS[0]);
  const sharedEventProperties = useAnalyticsSharedProperties({
    buttonState,
  });
  const counterMap = useInstance(() => new Map<string, number>());

  const trackEmojiSent = useThrottledCallback(
    () => {
      for (const [emoji, clickedCount] of counterMap.entries()) {
        analytics.trackEmojiSent({
          ...sharedEventProperties,
          emoji,
          clickedCount,
        });
      }
      counterMap.clear();
    },
    2000,
    {
      leading: false,
      trailing: true,
    }
  );

  useEffect(() => {
    return () => {
      trackEmojiSent.flush();
    };
    // NOTE: flush once sharedEventProperties changed.
  }, [sharedEventProperties, trackEmojiSent]);

  const handleSend = (emoji: string) => {
    api.send(emoji, user.id);
    setDefaultEmoji(emoji);
    props.onSend();

    counterMap.set(emoji, (counterMap.get(emoji) ?? 0) + 1);
    trackEmojiSent();
  };

  const handleExpand = () => {
    setButtonState('expanding');
    analytics.trackExpandButtonClicked(sharedEventProperties);
  };

  const handleCollapse = () => {
    setButtonState('collapsing');
    analytics.trackCollapseButtonClicked(sharedEventProperties);
  };

  return (
    <div className='relative w-14 h-10'>
      {/*  
        NOTE(guoqiang): register crowed frame subscription, so that we can show avatars
        in the emoji animation. Only register myself because others are already registered
        by TownhallRemoteStreamView (web-app/src/pages/Audience/Team/Townhall/TeamView.tsx).
      */}
      <CrowdFramesRenderlessAvatar
        clientId={myClientId as ClientId}
        profileIndex={ProfileIndex.wh100x100fps8}
      />

      {/* 
        NOTE(guoqiang): using absolute position to prevent chat-preview-container too width to 
        overlap with the game play view.
       */}
      <div className='absolute right-0 top-0'>
        {match(buttonState)
          .with('collapsed', () => (
            <Collapsed
              emoji={defaultEmoji}
              onSend={() => handleSend(defaultEmoji)}
              onExpand={handleExpand}
            />
          ))
          .with('expanding', () => (
            <Transition
              emojis={PRESET_EMOJIS}
              stage='expanding'
              onComplete={() => setButtonState('expanded')}
            />
          ))
          .with('expanded', () => (
            <Expanded
              emojis={PRESET_EMOJIS}
              onSend={(emoji) => handleSend(emoji)}
              onCollapse={handleCollapse}
            />
          ))
          .with('collapsing', () => (
            <Transition
              emojis={PRESET_EMOJIS}
              stage='collapsing'
              onComplete={() => setButtonState('collapsed')}
            />
          ))
          .exhaustive()}
      </div>
    </div>
  );
}
