import { useMyInstance } from '../../hooks/useMyInstance';
import { useVenueMode } from '../../hooks/useVenueMode';
import { GamePackContextProvider } from '../../pages/GamePack/Context';
import { VenueMode } from '../../types';
import { useMyClientType } from '../Venue/VenuePlaygroundProvider';
import { useVenue } from '../Venue/VenueProvider';
import { useTeamLabel } from './BannerCreateTeam';
import { BannerGame } from './BannerGame';
import { BannerSecondaryCTA } from './BannerSecondaryCTA';
import { BannerTeam } from './BannerTeam';
import { type LobbyDecisionData } from './useLobbyDecisionData';
import { useLobbyTeams } from './useLobbyTeams';

export function ClassicLobbyInternal(props: {
  onGameDetailsClick: () => void;
  decisionData: LobbyDecisionData;
}): JSX.Element | null {
  const [venue] = useVenue();
  const me = useMyInstance();
  const myClientType = useMyClientType();

  const venueMode = useVenueMode();
  const teamLabel = useTeamLabel();
  const teams = useLobbyTeams();

  const { decisionData } = props;
  if (!me) return null;

  return (
    <div
      className='w-full h-full flex flex-col gap-3'
      data-testid='lobby-internal'
      data-debugid='lobby-classic'
    >
      <div
        className='
          w-full flex-shrink-0 flex justify-center
          font-cairo font-black text-4xl text-shadow-lobby-title truncate
        '
      >
        {venue.displayName}
      </div>

      {/* 
        min-h-0 is to force this container to both fill the remaining space (via flex-1)
        but _also_ give itself a height limited by the flex container. This allows the
        scrollable section to be contained properly without resorting to overflow:hidden
      */}

      <div
        className='flex-1 w-full min-h-0 flex flex-col gap-1.5'
        data-testid='lobby-modal-teams'
      >
        <GamePackContextProvider
          noOnboardingTasks
          embed={true}
          pageType='public'
        >
          <BannerGame
            onGameDetailsClick={props.onGameDetailsClick}
            decisionData={decisionData}
            className='bg-lp-black-001 rounded-xl border border-secondary'
          />
        </GamePackContextProvider>

        <div className='w-full flex-1 overflow-y-auto scrollbar flex flex-col gap-1.5 rounded-xl'>
          <BannerSecondaryCTA me={me} decisionData={decisionData} />
          {teams.map((t) => (
            <BannerTeam
              key={t.id}
              me={me}
              myClientType={myClientType}
              isMyTeam={t.id === me.teamId}
              team={t}
              teamLabel={teamLabel}
              showTeamName={venueMode === VenueMode.Game || !!t.isStaffTeam}
              showButton
              decisionData={decisionData}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
