import React from 'react';

import { HostIcon } from '../icons/HostIcon';
import { getChatUserId } from './common';
import { useSelectChatHost } from './Context';
import { type RemoteTypingUser } from './service';

interface TypingIndicatorProps {
  remoteTypingUsers: RemoteTypingUser[];
}

const TypingIndicator = ({ remoteTypingUsers }: TypingIndicatorProps) => {
  const host = useSelectChatHost();
  let content = '';
  let active = false;
  let user = null;
  if (remoteTypingUsers.length > 0) {
    active = true;
    user = remoteTypingUsers[0].user;
    if (remoteTypingUsers.length === 1) {
      content = 'is typing...';
    } else if (remoteTypingUsers.length <= 6) {
      const others = remoteTypingUsers.length - 1;
      content = `and ${others} ${
        others === 1 ? 'other' : 'others'
      } are typing...`;
    } else {
      content = 'and many others are typing...';
    }
  }
  const isHost = host !== null && getChatUserId(host) === user?.id;

  return (
    <div
      className={`text-icon-gray bg-black text-3xs px-2.5 py-1 ${
        active ? 'visible' : 'invisible'
      }`}
    >
      <span className='max-w-30 truncate whitespace-nowrap'>
        {isHost && (
          <HostIcon className='w-3 h-3 inline mr-0.5 mb-0.5 fill-[#8b9294]' />
        )}
        {user?.username}
      </span>
      <span className='inline-block'>&nbsp;</span>
      <span className='inline-block'>{content}</span>
    </div>
  );
};

const Memoed = React.memo(TypingIndicator);
export { Memoed as TypingIndicator };
