export function DeviceBlockedIcon(): JSX.Element {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='108'
      height='108'
      fill='none'
      viewBox='0 0 108 108'
    >
      <circle cx='54' cy='54' r='54' fill='#232325'></circle>
      <path
        fill='#8B9294'
        fillRule='evenodd'
        d='M72.4 73.048C72.4 75.77 70.24 78 67.6 78H38.8c-2.64 0-4.8-2.229-4.8-4.952V48.286c0-2.724 2.16-4.953 4.8-4.953h2.4v-4.952C41.2 31.448 46.48 26 53.2 26s12 5.448 12 12.381v4.952h2.4c2.64 0 4.8 2.229 4.8 4.953v24.762zm-33.6 0V48.286h28.8v24.762H38.8zm14.4-17.334c-2.64 0-4.8 2.229-4.8 4.953s2.16 4.952 4.8 4.952c2.64 0 4.8-2.228 4.8-4.952 0-2.724-2.16-4.953-4.8-4.953zM46 43.334V38.38c0-4.21 3.12-7.429 7.2-7.429s7.2 3.22 7.2 7.429v4.952H46z'
        clipRule='evenodd'
      ></path>
    </svg>
  );
}
