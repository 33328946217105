import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useToggle } from 'react-use';

import { ClientTypeUtils } from '../../types/user';
import {
  type ContextMenuOptions,
  ContextMenuScope,
  useContextMenuContext,
} from '../ContextMenu/Context';
import { HostIcon } from '../icons/HostIcon';
import { useTeamWithStaff } from '../TeamAPI/TeamV1';
import { useMyClientType } from '../Venue/VenuePlaygroundProvider';
import { ChannelType, ErrorMessageType, getChatUserId } from './common';
import {
  useSelectChatHost,
  useSelectParticipantByRid,
  useSetError,
} from './Context';
import { type SCUserType } from './service';

interface Position {
  x: number;
  y: number;
}

interface ContextMenuHelperProps {
  clientId: string;
  position: Position;
  handleClose: () => void;
}

const ContextMenuHelper = ({
  clientId,
  position,
  handleClose,
}: ContextMenuHelperProps): JSX.Element => {
  const { options: contextMenuOptions, setOptions } = useContextMenuContext();
  const prevContextMenuOptions = useRef<ContextMenuOptions | null>(
    contextMenuOptions
  );
  const setError = useSetError();

  useEffect(() => {
    if (!clientId) {
      setError(ErrorMessageType.ChannelNotAvailableError, {
        channelType: ChannelType.Private,
      });
      return;
    }
    setOptions({
      x: position.x,
      y: position.y,
      clientId: clientId,
      insideRightPanel: true,
      scope: ContextMenuScope.Chat,
    });
  }, [clientId, setOptions, position, setError]);

  useEffect(() => {
    if (prevContextMenuOptions && !contextMenuOptions) {
      handleClose();
    }
    prevContextMenuOptions.current = contextMenuOptions;
  }, [contextMenuOptions, handleClose]);

  return <></>;
};

interface MessageUserProps {
  mUser: SCUserType;
  color: string;
}
const MessageUser = ({
  mUser,
  color,
}: MessageUserProps): JSX.Element | null => {
  const host = useSelectChatHost();
  const messageUserRef = useRef(null);
  const hostChatId = host ? getChatUserId(host) : null;
  const [open, setOpen] = useToggle(false);
  const [pos, setPos] = useState<Position>({ x: 0, y: 0 });

  const handleNameClick = (event: React.MouseEvent): void => {
    setOpen(true);
    setPos({ x: event.pageX, y: event.pageY });
    event.stopPropagation();
  };

  const handleClose = useCallback(() => {
    setOpen(false);
    setPos({ x: 0, y: 0 });
  }, [setOpen]);

  const participant = useSelectParticipantByRid(mUser.id);
  const userTeam = useTeamWithStaff(participant?.teamId);
  const myClientType = useMyClientType();

  // team name on others only in host view
  const showTeamName =
    ClientTypeUtils.isHost(myClientType) &&
    hostChatId !== mUser.id &&
    !!userTeam;

  return (
    <span className='relative' ref={messageUserRef}>
      <span
        className='font-bold whitespace-nowrap max-w-56 truncate cursor-pointer'
        onClick={handleNameClick}
        title={participant?.username || mUser.username}
        style={{
          color: color,
        }}
      >
        {hostChatId === mUser.id && (
          <HostIcon className='w-3 h-3 fill-current mr-0.5 mb-1 inline' />
        )}
        {participant?.username || mUser.username}
        {showTeamName && ' (' + userTeam?.name + ')'}
      </span>
      {open && (
        <ContextMenuHelper
          clientId={participant?.clientId || ''}
          position={pos}
          handleClose={handleClose}
        />
      )}
    </span>
  );
};

const Memoed = React.memo(MessageUser);
export { Memoed as MessageUser };
