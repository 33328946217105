import capitalize from 'lodash/capitalize';

import { useVenueMode } from '../../hooks/useVenueMode';
import { VenueMode } from '../../types';
import { useMyInstance } from '../Player';
import { useCreateTeam, useIsJoinTeamLocked } from '../TeamAPI/TeamV1';
import { useMyClientId } from '../Venue/VenuePlaygroundProvider';
import { type TeamLabel } from './types';

export const BannerCreateTeam = (props: {
  venueMode: VenueMode;
  teamLabel: TeamLabel;
  variant: 'primary' | 'secondary';
}): JSX.Element => {
  return (
    <div
      className={`w-full min-h-28 pl-7.5 pr-5 bg-lp-black-001 rounded-xl flex items-center justify-between border border-secondary`}
    >
      <div>Be the first to join this empty {capitalize(props.teamLabel)}</div>
      <CreateTeamButton
        venueMode={props.venueMode}
        teamLabel={props.teamLabel}
        variant={props.variant}
      />
    </div>
  );
};

export function useTeamLabel(): TeamLabel {
  const venueMode = useVenueMode();
  return venueMode === VenueMode.Game ? 'team' : 'room';
}

function CreateTeamButton(props: {
  teamLabel: TeamLabel;
  venueMode: VenueMode;
  variant: 'primary' | 'secondary';
}) {
  const createTeam = useCreateTeam();
  const isJoinLocked = useIsJoinTeamLocked();
  const myClientId = useMyClientId();
  const me = useMyInstance();

  const isLobbyMode = props.venueMode === VenueMode.Lobby;

  return (
    <button
      type='button'
      className={`
        ${props.variant === 'secondary' ? 'btn-secondary' : 'btn-primary'}
        w-30 h-10 flex-shrink-0
      `}
      onClick={() =>
        createTeam({
          memberId: myClientId,
          cohost: me?.cohost,
          debug: 'create-team-from-lobby',
        })
      }
      disabled={isJoinLocked}
    >
      {isLobbyMode ? 'Join' : 'Create'} {capitalize(props.teamLabel)}
    </button>
  );
}
