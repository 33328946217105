import { createContext, useContext, useEffect, useMemo } from 'react';

import { ConnectionStatus } from '@lp-lib/shared-schema';

import { useLiveCallback } from '../../hooks/useLiveCallback';
import {
  type ChatParticipant,
  ClientType,
  type Participant,
} from '../../types/user';
import { useIsLiveGamePlay } from '../Game/hooks';
import { useCohost, useHost } from '../Player';
import { useParticipantsAsArray } from '../Player';
import { useIsStreamSessionAliveOrAborted } from '../Session';
import { type ChatMode, type ErrorMessageType, type Recipient } from './common';
import type ChatService from './service';
import { type SCMessageType } from './service';

type SetErrorHandler = (
  errorType: ErrorMessageType,
  errorExtra?: Record<string, unknown>,
  timeout?: number
) => void;

interface ChatContext {
  chatService: ChatService;
  mode: ChatMode;
  setError: SetErrorHandler;
}

const Context = createContext<ChatContext | null>(null);

function useChatContext(): ChatContext {
  const ctx = useContext(Context);
  if (!ctx) {
    throw new Error('ChatContext is not in the tree!');
  }
  return ctx;
}

export const useChatService = (): ChatService => {
  const ctx = useChatContext();
  return ctx.chatService;
};

export const useChatMode = (): ChatMode => {
  const ctx = useChatContext();
  return ctx.mode;
};

export const useSetError = (): SetErrorHandler => {
  const ctx = useChatContext();
  return ctx.setError;
};

export function useSelectParticipantByRid(rid: string): Participant | null {
  const participants = useParticipantsAsArray({
    filters: ['status:connected'],
  });
  const p = useMemo(() => {
    const parts = rid.split('_');
    if (parts.length !== 2) {
      return null;
    }
    const target = {
      id: parts[1],
      clientType: parts[0] === 'h' ? ClientType.Host : ClientType.Audience,
    };
    return (
      participants.find(
        (p) => p.id === target.id && p.clientType === target.clientType
      ) ?? null
    );
  }, [participants, rid]);
  return p ?? null;
}

export function useSelectChatHost(): ChatParticipant | null {
  const isLive = useIsLiveGamePlay();
  const isStreamAliveOrAborted = useIsStreamSessionAliveOrAborted();
  const host = useHost(false);
  const cohost = useCohost();

  return useMemo(() => {
    const participant = isLive ? host : cohost;
    if (participant?.status === ConnectionStatus.Connected) return participant;
    if (isStreamAliveOrAborted) return participant;
    return null;
  }, [isLive, cohost, host, isStreamAliveOrAborted]);
}

export function useSelectChatAudiences(): ChatParticipant[] {
  return useParticipantsAsArray({
    filters: ['host:false', 'cohost:false', 'status:connected'],
    sorts: ['joinedAt:asc'],
  });
}

export function useTrackingLastMessageTimestamp(
  chatService: ChatService,
  messages: SCMessageType[],
  onTimestampChange?: () => void
): void {
  const lastMessage = messages[messages.length - 1];
  const lastCreatedTimestamp = useMemo(
    () =>
      lastMessage && lastMessage.created_at
        ? new Date(lastMessage.created_at).getTime()
        : null,
    [lastMessage]
  );
  const cb = useLiveCallback(() => {
    onTimestampChange?.();
  });

  useEffect(() => {
    if (
      lastCreatedTimestamp &&
      lastCreatedTimestamp !== chatService.lastMessageTimestamp
    ) {
      cb();
      chatService.lastMessageTimestamp = lastCreatedTimestamp;
    }
  }, [chatService, lastCreatedTimestamp, cb]);
}

export function buildDisplayName(recipient: Recipient | null): JSX.Element {
  return (
    <>
      {recipient?.icon}
      {recipient?.username}
    </>
  );
}

export const ChatContextProvider = Context.Provider;
