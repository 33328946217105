import { type SVGProps } from 'react';

const ChatTeamIcon = (props: SVGProps<SVGElement>): JSX.Element => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className={props.className || 'w-3 h-3 fill-current'}
      viewBox='0 0 12 12'
    >
      <path
        fill='#fff'
        d='M9.08 8.644v-.037a5.103 5.103 0 00-.178-.984A3.117 3.117 0 007.62 6.027a.168.168 0 01-.042-.247 1.992 1.992 0 10-1.65.775l.037.001a2.115 2.115 0 011.99 1.404c.018.055.033.11.043.167a.409.409 0 01-.208.439A3.837 3.837 0 013.85 8.39a2.51 2.51 0 01.521-1.109.508.508 0 00-.185-.816.503.503 0 00-.557.137 3.13 3.13 0 00-.67 1.231 5.11 5.11 0 00-.11.783.503.503 0 00.197.437 4.839 4.839 0 005.836 0l.007-.005.021-.018.017-.016.017-.017.018-.02.012-.015.018-.024.01-.016.015-.024.01-.02.012-.023.01-.024.007-.02.008-.029.005-.018.005-.028.004-.02.002-.026.001-.025v-.022zm-4.067-4.08a.987.987 0 111.974 0 .987.987 0 01-1.974 0zM1.722 7.633a1.883 1.883 0 01-.331-.071.324.324 0 01-.157-.513l.003-.003a.959.959 0 01.726-.333 1.334 1.334 0 10-1.219-.75.167.167 0 01-.041.204 1.956 1.956 0 00-.59.845 2.99 2.99 0 00-.111.604v.008L0 7.654v.021l.001.025.003.026.003.02.005.028.005.02.009.026.008.022.009.023.012.025.01.017.015.027.01.014.018.025.011.014.019.021.016.016.017.016.021.018.007.005.019.013.007.005c.406.3.882.49 1.384.552a.503.503 0 00.563-.487.512.512 0 00-.45-.513zM11.893 7.972l.018-.024.01-.015.015-.026.01-.017.012-.025.008-.023.009-.022.007-.026.006-.02.005-.027.003-.022.002-.025.002-.025v-.021l-.001-.03v-.008a2.99 2.99 0 00-.111-.604 1.956 1.956 0 00-.59-.845.167.167 0 01-.041-.204 1.335 1.335 0 10-1.22.75c.233 0 .458.085.633.24.037.035.07.074.098.117a.327.327 0 01-.183.498c-.098.03-.198.051-.3.064a.516.516 0 00-.457.515.503.503 0 00.563.486c.5-.06.977-.25 1.383-.551l.01-.007.016-.012.007-.005.022-.019.017-.015.017-.017.018-.02.012-.015z'
      />
    </svg>
  );
};

export { ChatTeamIcon };
