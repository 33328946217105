import { type SVGProps } from 'react';

const ChatDeleteIcon = (props: SVGProps<SVGElement>): JSX.Element => {
  return (
    <svg
      className={props.className || 'w-3 h-3 fill-current'}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 11 12'
    >
      <path d='M9.157 4.113a.568.568 0 00-.535.575v5.839a.369.369 0 01-.369.368H3.496a.369.369 0 01-.369-.368V4.665a.553.553 0 00-.569-.552.568.568 0 00-.535.575v6.76a.552.552 0 00.553.552h6.598a.552.552 0 00.553-.552V4.665a.552.552 0 00-.57-.552zM10.34 2.148H1.41a.552.552 0 100 1.105h8.93a.552.552 0 100-1.105zM4.724 1.105h2.302a.552.552 0 100-1.105H4.724a.552.552 0 100 1.105z' />
      <path d='M5.342 9.305V5.616a.552.552 0 10-1.105 0v3.689a.552.552 0 101.105 0zM7.513 9.305V5.616a.552.552 0 10-1.105 0v3.689a.552.552 0 001.105 0z' />
    </svg>
  );
};

export { ChatDeleteIcon };
