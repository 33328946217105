const colors = [
  '#D19F9F',
  '#D664A1',
  '#B33B66',
  '#A30E5E',
  '#D28AB1',
  '#CA73E0',
  '#9050CF',
  '#7D1BDF',
  '#96A8E7',
  '#708EF8',
  '#4B6CDF',
  '#1D4AE8',
  '#B6F0DE',
  '#87CEB9',
  '#58BD9F',
  '#0B996F',
];

export const getRandomColor = (): string =>
  colors[Math.floor(Math.random() * colors.length)];
