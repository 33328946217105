import { useMemo, useRef } from 'react';
import { match } from 'ts-pattern';

import { useFeatureQueryParam } from '../../hooks/useFeatureQueryParam';
import { useMyInstance } from '../../hooks/useMyInstance';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import { useVenueMode } from '../../hooks/useVenueMode';
import { ClientTypeUtils, isStaff } from '../../types/user';
import { VenueMode } from '../../types/venue';
import { TeamlessPanel, TeamsPanel } from '../AllTeams';
import Chat from '../Chat';
import { UnreadMessageCounter } from '../Chat/UnreadMessageCounter';
import { EmojiTriggerButton } from '../EmojiBoard/EmojiTrigger';
import { useIsLiveGamePlay } from '../Game/hooks';
import { ArrowDownIcon } from '../icons/Arrows';
import { ChatBubbleIcon } from '../icons/Chat/ChatBubbleIcon';
import { TeamIcon } from '../icons/TeamIcon';
import { useComposedLayoutAnchor } from '../LayoutAnchors/LayoutAnchors';
import { useIsPairingGamePlay } from '../Pairing';
import { useNumOfParticipants } from '../Player';
import {
  type RightPanelTabProps,
  RightPanelTabState,
  useRightPanelTab,
  useRightPanelUIAction,
} from '../RightPanelContext';
import { useIsTeamsOnTop } from '../TeamAPI/TeamV1';
import { useTownhallConfig, useTownhallShowTeam } from '../Townhall';
import { useMyClientType } from '../Venue/VenuePlaygroundProvider';
import { useZoomNumOfParticipants } from '../Zoom/ZoomVenueProvider';

const TabNames = {
  [RightPanelTabState.People]: 'People',
  [RightPanelTabState.MiniChat]: 'Chat',
  [RightPanelTabState.FullChat]: 'Chat',
} as const;

function useTabNames(tagConfig: FooterProps['tabConfig']): {
  [key in RightPanelTabState]: string;
} {
  const isOndGame = !useIsLiveGamePlay();
  return useMemo(() => {
    if (!isOndGame) return TabNames;
    return {
      ...TabNames,
      [RightPanelTabState.People]:
        tagConfig.people === 'teamless' ? 'People' : 'Teams',
    };
  }, [isOndGame, tagConfig.people]);
}

const ActiveVenueMembers = (): JSX.Element => {
  const zoomNumOfParticipants = useZoomNumOfParticipants();
  const numOfParticipants = useNumOfParticipants({
    filters: ['host:false', 'cohost:false', 'status:connected', 'staff:false'],
  });
  if (zoomNumOfParticipants > 0) {
    return (
      <>
        {numOfParticipants}/{zoomNumOfParticipants}
      </>
    );
  }
  return <>{numOfParticipants}</>;
};

const Header = ({
  tab,
  handlePanelUIAction,
  tabConfig,
}: RightPanelTabProps & FooterProps): JSX.Element => {
  const tabNames = useTabNames(tabConfig);
  return (
    <header
      className={`w-full h-14 flex items-center justify-between relative flex-none text-white pl-4 pr-3 ${
        tab === RightPanelTabState.MiniChat ? 'hidden' : ''
      }`}
    >
      <div className='text-sm font-bold'>
        {tab ? tabNames[tab] : null}
        {tab === RightPanelTabState.People ? (
          <>
            {' '}
            (<ActiveVenueMembers />)
          </>
        ) : (
          ''
        )}
      </div>
      <button
        type='button'
        className='icon-btn-no-shadow bg-transparent w-10 h-10 text-white'
        onClick={() => handlePanelUIAction({ input: 'click-collapse' })}
      >
        <ArrowDownIcon />
      </button>
    </header>
  );
};

function ClassicFooterButton(props: {
  'data-testid': string;
  elementRef?: (el: HTMLElement | SVGElement | null) => void;
  disabled?: boolean;
  thisTab: RightPanelTabState;
  selectedTab: RightPanelTabState | null;
  icon: JSX.Element;
  notificationElement?: JSX.Element | null;
  onClick: () => void;
  variant?: 'wide' | 'normal';
  tabNames: ReturnType<typeof useTabNames>;
}) {
  const tabNames = props.tabNames;
  const variantStyles =
    props.variant === 'wide'
      ? 'w-full rounded-1.5lg flex-row gap-2.5'
      : 'w-17 flex-col';

  const textColor =
    props.selectedTab === props.thisTab
      ? 'text-white'
      : 'text-icon-gray hover:text-icon-gray'; // overrides the parent's hover effect;

  return (
    <button
      type='button'
      data-testid={props['data-testid']}
      ref={props.elementRef}
      disabled={props.disabled}
      className={`
        icon-btn-no-shadow p-3 text-2xs bg-transparent
        h-full flex items-center justify-center relative
        ${variantStyles}
        ${textColor}
      `}
      onClick={props.onClick}
    >
      <span className='relative'>
        {props.icon}
        {props.notificationElement}
      </span>
      <span>{tabNames[props.thisTab]}</span>
    </button>
  );
}

interface FooterProps {
  tabConfig: {
    chat: 'enabled' | 'disabled' | 'hidden';
    people: 'enabled' | 'disabled' | 'hidden' | 'teamless';
  };
}

function ClassicFooter({
  tab,
  tabConfig,
  handlePanelUIAction,
}: FooterProps & RightPanelTabProps) {
  const peopleButtonMeasurementRef = useComposedLayoutAnchor(
    'right-panel-people-footer-button'
  );

  const visibleButtonCount = Object.values(tabConfig).reduce(
    (total, config) => {
      total += config !== 'hidden' ? 1 : 0;
      return total;
    },
    0
  );

  const buttonVariant = visibleButtonCount === 2 ? 'wide' : 'normal';
  const tabNames = useTabNames(tabConfig);

  if (tabConfig.chat === 'hidden' && tabConfig.people === 'hidden') {
    return null;
  }

  return (
    <footer
      className={`
        w-full flex flex-none p-0.5 ${
          visibleButtonCount === 2 ? 'gap-1.5' : 'gap-2'
        } justify-center h-16 items-center
        rounded-xl

        ${tab === RightPanelTabState.MiniChat ? 'bg-main-layer' : ''}
      `}
    >
      {tabConfig.chat === 'hidden' ? null : (
        <ClassicFooterButton
          data-testid='chat-panel-btn'
          disabled={tabConfig.chat === 'disabled'}
          thisTab={RightPanelTabState.FullChat}
          selectedTab={tab}
          icon={<ChatBubbleIcon />}
          onClick={() => handlePanelUIAction({ input: 'click-chat' })}
          notificationElement={
            tab === RightPanelTabState.People ? (
              <div className='absolute top-0 left-full transform -translate-x-1/2 -translate-y-1/2'>
                <UnreadMessageCounter />
              </div>
            ) : undefined
          }
          variant={buttonVariant}
          tabNames={tabNames}
        />
      )}

      {tabConfig.people === 'hidden' ? null : (
        <ClassicFooterButton
          data-testid='teams-panel-btn'
          elementRef={peopleButtonMeasurementRef}
          disabled={tabConfig.people === 'disabled'}
          thisTab={RightPanelTabState.People}
          selectedTab={tab}
          icon={<TeamIcon />}
          onClick={() => handlePanelUIAction({ input: 'click-people' })}
          notificationElement={
            <span className='absolute top-0 left-full transform -translate-x-1/2 -translate-y-1/2 text-4xs font-bold'>
              <ActiveVenueMembers />
            </span>
          }
          variant={buttonVariant}
          tabNames={tabNames}
        />
      )}
    </footer>
  );
}

function TownhallFooterButton(props: {
  'data-testid': string;
  elementRef?: (el: HTMLElement | SVGElement | null) => void;
  disabled?: boolean;
  thisTab: RightPanelTabState;
  selectedTab: RightPanelTabState | null;
  icon: JSX.Element;
  notificationElement?: JSX.Element | null;
  onClick: () => void;
}) {
  return (
    <button
      type='button'
      data-testid={props['data-testid']}
      ref={props.elementRef}
      disabled={props.disabled}
      className={`icon-btn relative ${
        props.selectedTab === props.thisTab ? 'btn-primary' : ''
      }`}
      onClick={props.onClick}
    >
      <span className='relative'>{props.icon}</span>
      {props.notificationElement}
    </button>
  );
}

function TownhallFooter({
  tab,
  handlePanelUIAction,
  tabConfig,
}: FooterProps & RightPanelTabProps): JSX.Element {
  const peopleButtonMeasurementRef = useComposedLayoutAnchor(
    'right-panel-people-footer-button'
  );
  const emojiBoardEnabled = useFeatureQueryParam('emoji-board');

  return (
    <footer className='flex w-full justify-end mr-2'>
      <div className='flex gap-2' data-rightpanel-inside={true}>
        {emojiBoardEnabled && (
          <EmojiTriggerButton
            onSend={() => handlePanelUIAction({ input: 'send-emoji' })}
          />
        )}

        {tabConfig.people !== 'hidden' && (
          <TownhallFooterButton
            elementRef={peopleButtonMeasurementRef}
            data-testid='teams-panel-btn'
            thisTab={RightPanelTabState.People}
            selectedTab={tab}
            icon={<TeamIcon />}
            onClick={() => handlePanelUIAction({ input: 'click-people' })}
          />
        )}
        {tabConfig.chat !== 'hidden' && (
          <TownhallFooterButton
            data-testid='chat-panel-btn'
            thisTab={RightPanelTabState.FullChat}
            selectedTab={tab}
            icon={<ChatBubbleIcon />}
            onClick={() => handlePanelUIAction({ input: 'click-chat' })}
            notificationElement={
              tab !== RightPanelTabState.FullChat ? (
                <div className='absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 text-4xs font-bold'>
                  <UnreadMessageCounter />
                </div>
              ) : undefined
            }
          />
        )}
      </div>
    </footer>
  );
}

function useAutoCloseRightPanel(
  ref: React.MutableRefObject<HTMLDivElement | null>
) {
  const handlePanelUIAction = useRightPanelUIAction();
  useOutsideClick(ref, (ev) => {
    const dataAttr: keyof typeof PropConsiderClicksAsInsideRightPanel =
      'data-rightpanel-inside';
    const selector = `[${dataAttr}=true]`;
    const isConsideredInside =
      ev.target && ev.target instanceof Element
        ? !!ev.target.closest(selector)
        : false;

    if (isConsideredInside) return;
    handlePanelUIAction({ input: 'click-outside-panel' });
  });
}

function ClassicRightPanel(
  props: { showTeamColor: boolean } & FooterProps
): JSX.Element {
  const tab = useRightPanelTab();
  const handlePanelUIAction = useRightPanelUIAction();
  const ref = useRef<HTMLDivElement | null>(null);
  const isHost = ClientTypeUtils.isHost(useMyClientType());
  const isPairingGamePlay = useIsPairingGamePlay();
  const me = useMyInstance();
  const venueMode = useVenueMode();

  useAutoCloseRightPanel(ref);

  const panelProps = {
    tab,
    handlePanelUIAction,
  };

  return (
    <div
      data-debugid='right-panel-classic'
      ref={ref}
      className={`w-72 ${
        tab === RightPanelTabState.MiniChat
          ? 'h-auto bg-transparent'
          : 'h-[86vh] bg-lp-black-004'
      } flex flex-col items-center gap-0.5 absolute right-2 bottom-2 z-40 rounded-xl`}
    >
      <Header {...panelProps} tabConfig={props.tabConfig} />
      <section
        className={`flex-grow flex flex-col justify-end w-full min-h-0 ${
          // In TvT, hide just chat for small
          // screens and rely on Chat/Teams buttons to show.
          tab === RightPanelTabState.MiniChat ? 'hidden lg:flex' : ''
        }`}
      >
        {props.tabConfig.chat !== 'hidden' && (
          <Chat
            {...panelProps}
            miniChatConfig={{
              enabled: true,
              version: 'v1',
              inputEnabled: true,
            }}
          />
        )}
        {props.tabConfig.people !== 'hidden' && (
          <TeamsPanel {...panelProps} showTeamColor={props.showTeamColor} />
        )}
      </section>
      <ClassicFooter
        {...panelProps}
        tabConfig={{
          people:
            isHost || isStaff(me)
              ? 'enabled'
              : props.tabConfig.people === 'hidden'
              ? 'hidden'
              : venueMode === VenueMode.Lobby || isPairingGamePlay
              ? 'disabled'
              : 'enabled',
          chat: props.tabConfig.chat,
        }}
      />
    </div>
  );
}

function MiniFooterRightPanel(props: FooterProps): JSX.Element {
  const tab = useRightPanelTab();
  const handlePanelUIAction = useRightPanelUIAction();
  const ref = useRef<HTMLDivElement | null>(null);
  const zeroStateEnabled = useFeatureQueryParam('team-panel-zero-state');
  const venueMode = useVenueMode();
  const me = useMyInstance();
  const lobbyMode = venueMode === VenueMode.Lobby && zeroStateEnabled;
  const showTeam = useTownhallShowTeam();

  useAutoCloseRightPanel(ref);

  const panelProps = {
    tab,
    handlePanelUIAction,
  };

  const noActiveTab = tab === RightPanelTabState.MiniChat;

  return (
    <div
      data-debugid='right-panel-mini-footer'
      className='flex flex-col items-end absolute right-2 bottom-2 z-40'
    >
      <div
        ref={ref}
        className={`w-72 ${
          noActiveTab ? 'h-auto bg-transparent' : 'h-[72vh] bg-lp-black-004'
        } flex flex-col items-center gap-0.5 rounded-xl mb-1.5`}
      >
        <Header {...panelProps} tabConfig={props.tabConfig} />
        <section
          className={
            'flex-grow flex flex-col justify-end gap-0.5 w-full min-h-0'
          }
        >
          {props.tabConfig.chat !== 'hidden' && (
            <Chat
              {...panelProps}
              miniChatConfig={{
                enabled: true,
                version: 'v2',
                inputEnabled: false,
              }}
            />
          )}
          {match(props.tabConfig.people)
            .when(
              (v) => v === 'enabled' || v === 'disabled',
              () => (
                <TeamsPanel
                  {...panelProps}
                  showTeamColor={showTeam}
                  showZeroState={lobbyMode && !isStaff(me)}
                />
              )
            )
            .when(
              (v) => v === 'teamless',
              () => <TeamlessPanel {...panelProps} />
            )
            .otherwise(() => null)}
        </section>
      </div>
      <TownhallFooter {...panelProps} tabConfig={props.tabConfig} />
    </div>
  );
}

export const PropConsiderClicksAsInsideRightPanel = {
  'data-rightpanel-inside': true,
} as const;

export function RightPanel(props: {
  chat: FooterProps['tabConfig']['chat'];
  people: FooterProps['tabConfig']['people'];
}): JSX.Element {
  const teamsOnTop = useIsTeamsOnTop();
  const townhallConfig = useTownhallConfig();
  const townhallShowTeam = useTownhallShowTeam();
  const isOndGame = !useIsLiveGamePlay();
  const chatEnabled = useFeatureQueryParam('chat');

  const tabConfig = useMemo(
    () => ({
      chat: chatEnabled ? props.chat : 'hidden',
      people: props.people,
    }),
    [props.chat, props.people, chatEnabled]
  );

  if (teamsOnTop && isOndGame) {
    return <MiniFooterRightPanel tabConfig={tabConfig} />;
  }

  return (
    <ClassicRightPanel
      // this is primarily used in host view
      showTeamColor={townhallConfig.enabled && townhallShowTeam}
      tabConfig={tabConfig}
    />
  );
}
