import { type ReactNode, useMemo } from 'react';

import { useAnalytics } from '../../analytics/AnalyticsContext';
import { useMyInstance } from '../../hooks/useMyInstance';
import { useVenueMode } from '../../hooks/useVenueMode';
import { ClientTypeUtils, VenueMode } from '../../types';
import { useOpenGamePackDetailModal } from '../Game/GamePack';
import {
  useFetchGameSessionGamePack,
  useHasReceivedInitial,
  useIsLiveGamePlay,
  useOndGameState,
} from '../Game/hooks';
import { useShowPreGame } from '../Game/PreGame/Provider';
import { useLayoutAnchorRect } from '../LayoutAnchors/LayoutAnchors';
import { useMyTeamId } from '../Player';
import { type IFeatureChecker } from '../Product/FeatureChecker';
import { useIsStreamSessionAliveOrAborted } from '../Session';
import { useAutoJoinTeam } from '../TeamAPI/TeamV1';
import { useTownhallEnabled, useTownhallInited } from '../Townhall';
import { useMyClientType } from '../Venue/VenuePlaygroundProvider';
import { LobbyAnalytics } from './analytics';
import { ClassicLobbyInternal } from './ClassicLobbyInternal';
import { type LobbyWidgetKey } from './LobbyGameWidgets';
import {
  LobbyGuestConversionWizard,
  useAutoCloseGuestConversionWizard,
} from './LobbyGuestConversion';
import { useLobbyState } from './Provider';
import { TownhallLobbyInternal } from './TownhallLobbyInternal';
import { useLobbyDecisionData } from './useLobbyDecisionData';

const lobbyContainerClassNames = 'min-w-160 w-160 2xl:w-212 h-2/3';

function LobbyModal(props: {
  townhallEnabled: boolean;
  waitTeamViewInited?: boolean;
  children: ReactNode;
}) {
  const { townhallEnabled, waitTeamViewInited } = props;
  const myTeamId = useMyTeamId();
  const isHost = ClientTypeUtils.isHost(useMyClientType());
  const venueMode = useVenueMode();
  const teamsRect = useLayoutAnchorRect('lobby-top-spacing-anchor');
  const townhallLobby = townhallEnabled && venueMode === VenueMode.Lobby;
  const townhallGame = townhallEnabled && venueMode === VenueMode.Game;

  const style = useMemo(() => {
    const isNoTeamAudience = !isHost && !myTeamId;
    const blockNoTeamAudience =
      isNoTeamAudience && (!townhallEnabled || townhallGame);
    return {
      zIndex: blockNoTeamAudience ? 'z-50' : 'z-5',
      background: blockNoTeamAudience ? 'bg-lp-black-004' : 'bg-auto',
      marginTop: !isHost && townhallLobby ? 'mt-2' : 'mt-12',
    };
  }, [isHost, myTeamId, townhallEnabled, townhallGame, townhallLobby]);

  // Note(Jialin): there are a few steps to be done first such as team recovery,
  // auto join/create team before the team view ready. So the lobby is rendered
  // first, and then the team view. However, the top offset here depends on the
  // team view, which caused the layout shifting, and the page looks choppy.
  // Once the current user joined a team, the team view must render the local
  // player, and the height becomes non-zero. We use this to avoid the shifting.
  // (Disable the following line in townhall mode and see how it looks like)
  const initLayout =
    townhallLobby && !isHost && waitTeamViewInited && !teamsRect?.height;

  const topOffest =
    townhallLobby && teamsRect ? `${teamsRect.height}px` : undefined;
  return (
    <div
      className={`w-full h-full absolute px-80 ${style.zIndex} ${
        style.background
      } flex justify-center ${initLayout ? 'invisible' : 'visible'}`}
      style={{
        top: topOffest,
      }}
    >
      <div
        className={`${lobbyContainerClassNames} flex flex-col items-center
        pointer-events-on text-white relative ${style.marginTop}`}
      >
        {props.children}
      </div>
    </div>
  );
}

function AutoJoiner() {
  useAutoJoinTeam();
  return null;
}

function LobbyInternal(props: {
  waitTeamViewInited?: boolean;
  featureChecker?: IFeatureChecker;
  disabledWidgets?: LobbyWidgetKey[];
}) {
  // note: we used to have an animation that was triggered by the stream state. when the animation hit the 'done'
  // state, the lobby hid itself. since we no longer have that animation, we just depend on the stream state directly.
  const streamAlive = useIsStreamSessionAliveOrAborted();
  const hasReceivedSessionData = useHasReceivedInitial('session');
  const hasReceivedOndState = useHasReceivedInitial('ondState');
  const isLiveGame = useIsLiveGamePlay();
  const decisionData = useLobbyDecisionData(
    props.featureChecker,
    props.disabledWidgets
  );
  const me = useMyInstance();

  const state = useLobbyState();
  const ondState = useOndGameState();
  const showPreGame = useShowPreGame();
  const pack = useFetchGameSessionGamePack();

  const hideLobby =
    state.hide ||
    // ond case
    (!!ondState && (!!me?.teamId || ClientTypeUtils.isHost(me))) ||
    // live game case
    (isLiveGame &&
      streamAlive &&
      (ClientTypeUtils.isHost(me) || !!me?.teamId)) ||
    // if we're in the pregame, and the user is on a team, then we'll hide the lobby for a smoother transition into the
    // pregame experience. otherwise, the lobby and pregame will overlap momentarily until the data settles.
    (!!me?.teamId && showPreGame);

  const openGamePackDetailModal = useOpenGamePackDetailModal();

  const townhallEnabled = useTownhallEnabled();
  const townhallInited = useTownhallInited();
  useAutoCloseGuestConversionWizard(hideLobby);

  const analytics = useAnalytics();
  const lobbyAnalytics = useMemo(
    () => new LobbyAnalytics(analytics),
    [analytics]
  );

  // if we haven't received the session or ond state data, we shouldn't trust it. show nothing until the data is ready.
  if (!hasReceivedSessionData || !hasReceivedOndState) return null;

  if (!me || !decisionData || !townhallInited) return null;

  return (
    <>
      {!hideLobby && (
        <LobbyModal
          townhallEnabled={townhallEnabled}
          waitTeamViewInited={props.waitTeamViewInited}
        >
          {townhallEnabled ? (
            <TownhallLobbyInternal
              decisionData={decisionData}
              onGameDetailsClick={() => {
                if (pack) {
                  openGamePackDetailModal(pack);
                }
              }}
              analytics={lobbyAnalytics}
              forceOneTeam={pack?.teamRandomizationSettings?.oneTeam}
            />
          ) : (
            <ClassicLobbyInternal
              decisionData={decisionData}
              onGameDetailsClick={() => {
                if (pack) {
                  openGamePackDetailModal(pack);
                }
              }}
            />
          )}
        </LobbyModal>
      )}

      {!!state.ondConversion && (
        <LobbyGuestConversionWizard
          initial={state.ondConversion}
          analytics={lobbyAnalytics}
        />
      )}
    </>
  );
}

export const Lobby = (props: {
  waitTeamViewInited?: boolean;
  featureChecker?: IFeatureChecker;
  disabledWidgets?: LobbyWidgetKey[];
}): JSX.Element => {
  return (
    <>
      <AutoJoiner />
      <LobbyInternal {...props} />
    </>
  );
};
