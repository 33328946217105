import {
  GameLikePicker,
  type PublicPickerProps,
} from '../../components/Game/GameCenter/Picker';
import { type GamePack } from '../../types/game';
import { useGamePackContext } from './Context';
import { DiscoveryLibrary, PublicLibraryV1 } from './Library';
import {
  MyGamePackList,
  PlayAgainGamePackList,
  SearchGamePackList,
  TagGamePackList,
  UntaggedGamePackList,
} from './List';
import { MyGamePacks } from './MyGamePacks';

export function GamePackPicker(
  props: PublicPickerProps<GamePack>
): JSX.Element {
  const ctx = useGamePackContext();
  return (
    <GameLikePicker
      {...props}
      type='gamePack'
      ctx={ctx}
      viewMap={{
        MyGameLikeList: MyGamePackList,
        TagGameLikeList: TagGamePackList,
        UntaggedGameLikeList: UntaggedGamePackList,
        SearchGameLikeList: SearchGamePackList,
        MyGameLikes: MyGamePacks,
        DiscoveryLibrary: DiscoveryLibrary,
        PublicLibrary: PublicLibraryV1,
        PlayAgainGameLikeList: PlayAgainGamePackList,
      }}
    />
  );
}
