import {
  type NetworkQuality,
  RemoteStreamType,
  type UID,
} from 'agora-rtc-sdk-ng';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLatest } from 'react-use';

import { type TrackState, WebRTCUtils } from '../../services/webrtc';
import { ClientTypeUtils } from '../../types';
import { assertExhaustive, err2s } from '../../utils/common';
import { useAmICohost, useParticipantFlags } from '../Player';
import { useModeAwareAudienceRTCService } from '../Townhall';
import {
  useMyClientId,
  useMyClientType,
} from '../Venue/VenuePlaygroundProvider';
import {
  useIsCoreChannelJoined,
  usePublishStream,
  useRTCService,
} from '../WebRTC';
import { BringOnStage } from './BringOnStage';
import { useSelectOnStageMembers, useStageMode } from './Context/hooks';
import { useStageRecovery } from './Context/hooks/useStageRecovery';
import { useStageControlAPI } from './Context/Provider';
import { InvitedNotice } from './InvitedNotice';
import { StageMode } from './types';

// This is used for all stage modes
export function useSetupRTCService(): boolean {
  const stageRTCService = useRTCService('stage');
  const audienceRTCService = useModeAwareAudienceRTCService();
  const myClientId = useMyClientId();
  const myClientType = useMyClientType();
  const flags = useParticipantFlags(myClientId);
  const stageMode = useStageMode();
  const stageMembers = useSelectOnStageMembers(stageMode);
  const uids = useMemo(() => stageMembers.map((m) => m.id), [stageMembers]);
  const latestUids = useLatest(uids);
  const stageJoined = useIsCoreChannelJoined('stage');
  const volumeMapCacheRef = useRef<Record<UID, number>>({});
  const [ready, setReady] = useState<boolean>(false);
  const stageControl = useStageControlAPI();
  const isCohost = useAmICohost();

  useEffect(() => {
    if (ClientTypeUtils.isHost(myClientType) || isCohost || !stageJoined)
      return;
    if (uids.length <= 4) {
      stageRTCService.setEncoderConfiguration('240p');
    } else {
      stageRTCService.setEncoderConfiguration('120p');
    }
  }, [uids.length, stageRTCService, myClientType, stageJoined, isCohost]);

  useEffect(() => {
    if (uids.length === 0 || !stageJoined) return;
    async function run() {
      const streamType =
        stageMode === StageMode.H2H || stageMode === StageMode.BLOCK_CONTROLLED
          ? RemoteStreamType.HIGH_STREAM
          : uids.length === 1
          ? RemoteStreamType.HIGH_STREAM
          : RemoteStreamType.LOW_STREAM;

      const promises = uids.map((uid) =>
        stageRTCService.setRemoteVideoStreamType(uid, streamType)
      );
      try {
        await Promise.all(promises);
      } catch (error) {
        stageRTCService.log.error('setRemoteVideoStreamType failed', error);
      }
    }
    run();
  }, [uids, stageRTCService, stageMode, stageJoined]);

  const publishedCallback = useCallback(
    async (uid: UID, mediaType: 'audio' | 'video') => {
      stageControl.updateStreamState(uid.toString(), {
        [`${mediaType}Ready`]: true,
      });
    },
    [stageControl]
  );

  const unpublishedCallback = useCallback(
    (uid: UID, mediaType: 'audio' | 'video') => {
      stageControl.updateStreamState(uid.toString(), {
        [`${mediaType}Ready`]: false,
      });
    },
    [stageControl]
  );

  const vTrackStateChanged = useCallback(
    (uid: UID, _oldState: TrackState | null, newState: TrackState | null) => {
      stageControl.updateStreamState(uid.toString(), {
        trackState: newState,
      });
    },
    [stageControl]
  );

  useEffect(() => {
    if (!stageJoined) return;
    for (const uid of latestUids.current) {
      const [audioTrack, videoTrack] = stageRTCService.getTracksByUid(uid);
      const videoTrackState = stageRTCService.getVideoTrackStateByUid(uid);
      if (audioTrack) publishedCallback(uid, 'audio');
      if (videoTrack) publishedCallback(uid, 'video');
      vTrackStateChanged(uid, null, videoTrackState);
    }
    const disposers: (() => void)[] = [];
    disposers.push(
      stageRTCService.on('remote-user-published', publishedCallback)
    );
    disposers.push(
      stageRTCService.on('remote-user-unpublished', unpublishedCallback)
    );
    disposers.push(
      stageRTCService.on(
        'user-volume-changed',
        (volumeMap: Record<UID, number>) => {
          const normalizedVolumeMap: Record<UID, number> = {};
          for (const [uid, vol] of Object.entries(volumeMap)) {
            const normalizedVolume = WebRTCUtils.IsSpeaking(vol) ? 1 : 0;
            normalizedVolumeMap[uid] = normalizedVolume;
            if (volumeMapCacheRef.current[uid] !== normalizedVolume) {
              stageControl.updateStreamState(uid.toString(), {
                volume: normalizedVolume,
              });
            }
          }
          volumeMapCacheRef.current = normalizedVolumeMap;
        }
      )
    );
    disposers.push(
      stageRTCService.on('video-track-state-changed', vTrackStateChanged)
    );
    disposers.push(
      stageRTCService.on('network-quality', (stats: NetworkQuality) => {
        stageControl.updateNetworkQuaility(
          myClientId,
          stats.uplinkNetworkQuality,
          stats.downlinkNetworkQuality
        );
      })
    );
    setReady(true);
    return () => {
      disposers.map((disposer) => disposer());
      setReady(false);
    };
  }, [
    latestUids,
    myClientId,
    publishedCallback,
    stageControl,
    stageJoined,
    stageRTCService,
    unpublishedCallback,
    vTrackStateChanged,
  ]);

  const { enqueueSynchronize } = usePublishStream(stageRTCService, {
    afterPublish: async () => {
      if (!audienceRTCService) return;
      try {
        audienceRTCService.muteAudio(true);
      } catch (error) {
        audienceRTCService.log.error('mute audio failed', err2s(error));
      }
    },
    afterUnpublish: async () => {
      if (!audienceRTCService) return;
      try {
        audienceRTCService.muteAudio(false);
      } catch (error) {
        audienceRTCService.log.error('unmute audio failed', err2s(error));
      }
    },
  });

  enqueueSynchronize(!ClientTypeUtils.isHost(myClientType) && !!flags?.onStage);

  return ready;
}

function StageModeView(): JSX.Element | null {
  const stageMode = useStageMode();

  switch (stageMode) {
    case StageMode.BOS:
      return <BringOnStage />;
    // Handled separately in each features
    case StageMode.H2H:
    case StageMode.SPOTLIGHT_BLOCK:
    case StageMode.BLOCK_CONTROLLED:
      return null;
    default:
      assertExhaustive(stageMode);
      return null;
  }
}

function StageRecovery() {
  useStageRecovery();
  return null;
}

export function Stage(props: {
  showInvitedNotice?: boolean;
}): JSX.Element | null {
  const ready = useSetupRTCService();
  const myClientId = useMyClientId();

  if (!ready || !myClientId) return null;

  return (
    <>
      <StageRecovery />
      <StageModeView />
      {props.showInvitedNotice && (
        <InvitedNotice
          className='w-screen h-full'
          contentClassName='w-125 h-125'
          zLayer='z-50'
          clientId={myClientId}
          showLocalStream
          showMessage
        />
      )}
    </>
  );
}
