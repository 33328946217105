import React, { type ReactNode, useCallback, useEffect, useRef } from 'react';

import { ClientTypeUtils } from '../../types/user';
import { ChatDeleteIcon } from '../icons/Chat/ChatDeleteIcon';
import { ChatReplyIcon } from '../icons/Chat/ChatReplyIcon';
import { useMyInstance, useParticipantsGetter } from '../Player';
import { type HandlePanelUIAction } from '../RightPanelContext';
import {
  ChannelType,
  ChatMode,
  type Fragment,
  getChannelTypeLabel,
  getChannelTypeTheme,
  getChatUserId,
  MessageFadeoutMS,
  MessageFragmentType,
  type Recipient,
  StreamChatMessageType,
} from './common';
import { useChatMode } from './Context';
import { MessageUser } from './MessageUser';
import { type SCMessageType, type SCUserType } from './service';
import { useChatSharedContext } from './SharedContext';
import { buildMessageFragments } from './transformer';

function ToMe(props: { children: ReactNode }): JSX.Element {
  return <span className='text-icon-gray'>{props.children}</span>;
}

function MessageFragment(props: {
  fontSize?: number;
  fontWeight?: string;
  children: ReactNode;
}): JSX.Element {
  return (
    <span
      className={`chat-message-fragment ${
        props.fontWeight ? 'font-bold' : 'font-normal'
      }`}
      style={{ fontSize: props.fontSize ? `${props.fontSize}px` : 'inherit' }}
    >
      {props.children}
    </span>
  );
}

interface MessageProps {
  message: SCMessageType;
  handleReply: (message: SCMessageType) => void;
  handleDelete: (message: SCMessageType) => void;
  handlePanelUIAction?: HandlePanelUIAction;
  fadeoutMessage: boolean;
  fadeoutMessageOpacity: string;
  privateChannelEnabled: boolean;
  findRecipientByRid: (rid: string) => Recipient | null;
}

const Message = ({
  message,
  handleReply,
  handleDelete,
  handlePanelUIAction,
  fadeoutMessage,
  fadeoutMessageOpacity,
  privateChannelEnabled,
  findRecipientByRid,
}: MessageProps) => {
  const chatMode = useChatMode();
  const me = useMyInstance();
  const extensions = message.extensions;
  const channelType = extensions?.channelType || ChannelType.Public;
  const theme = getChannelTypeTheme(channelType, chatMode);
  const localUid = me ? getChatUserId(me) : '';
  const remoteUid = message.user.id;
  const replyable = localUid !== remoteUid && privateChannelEnabled;
  const deletable = me ? ClientTypeUtils.isHost(me) : false;
  const isMessageDeleted = message.type === StreamChatMessageType.Deleted;
  const messageRef = useRef<HTMLLIElement | null>(null);
  const fadeoutTimerIdRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const { setChatToClientId } = useChatSharedContext();
  const getParticipants = useParticipantsGetter();

  const getMUser = (): SCUserType => {
    const recipient = findRecipientByRid(extensions?.receiverRid ?? '');
    if (!recipient && extensions?.receiverClientId) {
      const participants = getParticipants();
      const p = participants[extensions.receiverClientId];
      if (p) {
        return {
          id: p.id,
          username: p.username,
          color: '',
        };
      }
    }
    return {
      id: recipient?.id || '',
      username: recipient?.username || 'unknown',
      color: '',
    };
  };

  let mUser: SCUserType;
  if (channelType === ChannelType.Public) {
    mUser = message.user;
  } else if (channelType === ChannelType.Group) {
    mUser = message.user;
  } else {
    mUser = localUid === remoteUid ? getMUser() : message.user;
  }

  const handleReplyClick = useCallback(() => {
    if (message.extensions?.channelType === ChannelType.Public) {
      setChatToClientId(message.extensions.senderClientId);
    } else {
      handleReply(message);
    }
  }, [message, setChatToClientId, handleReply]);

  const handleDeleteClick = useCallback(() => {
    handleDelete(message);
  }, [handleDelete, message]);

  const buildMessageUser = (color: string) => {
    return <MessageUser mUser={mUser} color={color} />;
  };

  const generateMessageFragment = (f: Fragment, idx: number) => {
    let fontSize;
    let fontWeight;
    if (f.type === MessageFragmentType.Strong) {
      fontWeight = 'bold';
    } else if (f.type === MessageFragmentType.PlainLarge) {
      fontSize = 32;
    }
    return (
      <MessageFragment key={idx} fontSize={fontSize} fontWeight={fontWeight}>
        {f.type === MessageFragmentType.Link && (
          <a
            target='_blank'
            href={f.fragment}
            rel='nofollow noreferrer noopener'
          >
            {f.fragment}
          </a>
        )}
        {f.type !== MessageFragmentType.Link && <>{f.fragment}</>}
      </MessageFragment>
    );
  };

  const primaryColor = '#01ACC4';
  let from;
  let to;
  if (channelType === ChannelType.Public) {
    from =
      localUid === remoteUid ? (
        <ToMe>Me</ToMe>
      ) : (
        buildMessageUser(message.user.color)
      );
  } else if (channelType === ChannelType.Group) {
    from =
      localUid === remoteUid ? <ToMe>Me</ToMe> : buildMessageUser(primaryColor);
    to = <ToMe>to my team</ToMe>;
  } else {
    [from, to] =
      localUid === remoteUid
        ? [<ToMe>Me to</ToMe>, buildMessageUser(primaryColor)]
        : [buildMessageUser(primaryColor), <ToMe>to me</ToMe>];
  }

  /**
   * Fadeout the message
   */
  useEffect(() => {
    if (fadeoutMessage) {
      if (fadeoutTimerIdRef.current) {
        clearTimeout(fadeoutTimerIdRef.current);
      }
      fadeoutTimerIdRef.current = setTimeout(() => {
        messageRef.current?.classList.add(fadeoutMessageOpacity);
      }, MessageFadeoutMS);
    } else {
      if (fadeoutTimerIdRef.current) {
        clearTimeout(fadeoutTimerIdRef.current);
      }
      messageRef.current?.classList.remove(...[fadeoutMessageOpacity]);
    }
  }, [fadeoutMessage, fadeoutMessageOpacity]);

  return (
    <li
      className='
        transition-opacity duration-1000 first:mt-auto mt-0.5 list-none rounded-1.5lg break-words
        relative
        group
        after:hover:absolute after:hover:w-full after:hover:h-full after:hover:inset-0
        after:hover:rounded-1.5lg
        after:hover:content-[""] after:hover:bg-lp-gray-002
        after:hover:pointer-events-none
      '
      style={{
        backgroundColor: theme.background,
      }}
      ref={messageRef}
    >
      <div
        className='p-2.5 relative group-hover:z-3'
        onClick={() =>
          handlePanelUIAction &&
          handlePanelUIAction({ input: 'click-chat-message' })
        }
      >
        <div
          className='text-sm font-bold mb-1'
          style={{
            color: theme.label,
          }}
        >
          {getChannelTypeLabel(channelType)}
        </div>
        <div className='break-words'>
          <span className='chat-message-source mr-2 text-icon-gray select-none'>
            {from}
            {to && ` `}
            {to}
            <span>:</span>
          </span>
          <span
            className={`${
              isMessageDeleted ? 'text-icon-gray' : 'text-white'
            } align-middle`}
          >
            {isMessageDeleted
              ? '[message deleted]'
              : buildMessageFragments(message).map((f, idx) =>
                  generateMessageFragment(f, idx)
                )}
          </span>
        </div>
        {!isMessageDeleted && chatMode === ChatMode.Full && (
          <div className='absolute -top-3 right-2'>
            <div className='chat-message-btn-group rounded-md bg-secondary border border-secondary hidden group-hover:flex flex-row items-center p-px'>
              {replyable && (
                <button
                  type='button'
                  className='chat-message-btn text-icon-gray hover:bg-lp-black-003
                  hover:text-white active:bg-lp-black-003 active:text-white'
                  onClick={handleReplyClick}
                >
                  <ChatReplyIcon />
                </button>
              )}
              {deletable && (
                <button
                  type='button'
                  className='chat-message-btn text-red-002 hover:bg-lp-red-001 hover:text-white active:bg-lp-red-001 active:text-white'
                  onClick={handleDeleteClick}
                >
                  <ChatDeleteIcon />
                </button>
              )}
            </div>
          </div>
        )}
      </div>
    </li>
  );
};

const Memoed = React.memo(Message);
export { Memoed as Message };
