import { type SVGProps } from 'react';

const ChatReplyIcon = (props: SVGProps<SVGElement>): JSX.Element => {
  return (
    <svg
      className={props.className || 'w-3 h-3 fill-current'}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 12 11'
    >
      <path d='M4.8 10.286a.61.61 0 01-.424-.173l-4.2-4.125A.588.588 0 010 5.571a.58.58 0 01.176-.416l4.2-4.125A.603.603 0 015.03.902c.11.045.203.12.269.217s.101.21.101.327v1.79c5.309.374 6.573 5.688 6.586 5.743a.58.58 0 01-.04.371.593.593 0 01-.257.273.61.61 0 01-.713-.1c-.013-.011-1.58-1.451-5.576-1.584v1.757a.584.584 0 01-.176.417.606.606 0 01-.424.173zM1.448 5.57L4.2 8.274v-.935c0-.156.063-.306.176-.416A.606.606 0 014.8 6.75c1.857-.05 3.704.28 5.424.97a5.804 5.804 0 00-2.21-2.452A5.954 5.954 0 004.8 4.393a.606.606 0 01-.424-.173.584.584 0 01-.176-.417V2.87L1.448 5.57z' />
    </svg>
  );
};

export { ChatReplyIcon };
