import { useEffect, useMemo } from 'react';

import { getFeatureQueryParamArray } from '../../hooks/useFeatureQueryParam';
import { useIsCoordinator, useMyInstance } from '../../hooks/useMyInstance';
import { useRSHook } from '../../hooks/useRSHook';
import { useVenueMode } from '../../hooks/useVenueMode';
import { ClientTypeUtils, type Participant, VenueMode } from '../../types';
import { type Game, type GamePack } from '../../types/game';
import { useGameHostingCoordinator } from '../Game/GameHostingProvider';
import {
  useFetchGameSessionGamePackPlus,
  useIsLiveGamePlay,
  useOndGameState,
} from '../Game/hooks';
import { useIsPairingGamePlay } from '../Pairing';
import { useNumOfParticipants, useParticipant } from '../Player';
import { type IFeatureChecker } from '../Product/FeatureChecker';
import { useTownhallEnabled } from '../Townhall';
import { useVenueEvent, type VenueTrimmedEvent } from '../Venue';
import { useMyClientType } from '../Venue/VenuePlaygroundProvider';
import { type LobbyWidgetKey } from './LobbyGameWidgets';

export interface ShadowGamePackInfo {
  roundId: string;
  totalCount: number;
  remainingCount: number;
  highScore: null | number;
}

export type LobbyDecisionData = {
  isPairingGame: boolean;
  shadowGamePacksInfo: null | ShadowGamePackInfo;
  userHasTeam: boolean;
  userIsAlone: boolean;
  userIsHostClient: boolean;
  gameIsInProgress: boolean;
  gameOrGamePack: Game | GamePack | null;
  gamePackPlus: ReturnType<typeof useFetchGameSessionGamePackPlus>;
  isOndGame: boolean;
  coordinator: Participant | null;
  userIsCoordinator: boolean;
  townhallEnabled: boolean;
  event: Nullable<VenueTrimmedEvent>;
  featureChecker?: IFeatureChecker;
  disabledWidgets?: LobbyWidgetKey[];
};

/**
 * The Lobby currently has many permutations dependent on the various states
 * collected here. By consolidating and giving these states a name (such as
 * userIsAlone), it makes the permutations slightly more manageable. Please add
 * more named decisions here if any additional conditions are needed, instead of
 * querying them directly into the components where this data is used.
 */
export function useLobbyDecisionData(
  featureChecker?: IFeatureChecker,
  disabledWidgets?: LobbyWidgetKey[],
  usePlayHistory = getFeatureQueryParamArray('game-on-demand-use-play-history')
): LobbyDecisionData | null {
  const { miss } = useRSHook('use-lobby-decision-data');
  const me = useMyInstance();

  const isLiveGame = useIsLiveGamePlay();
  const ondGamePlayState = useOndGameState();
  const venueMode = useVenueMode();

  const isPairingGame = useIsPairingGamePlay();
  // const isPairingGamePackLoaded = useIsPairingGamePackLoaded();
  // const pairing = usePairing();

  const userIsCoordinator = useIsCoordinator();
  const playHistoryTargetId =
    userIsCoordinator && usePlayHistory === 'enabled' ? me?.id ?? null : null;
  const gamePackPlus = useFetchGameSessionGamePackPlus(
    userIsCoordinator,
    playHistoryTargetId
  );

  const revalidateGamePackPlus = gamePackPlus?.mutate;
  // Manually trigger revalidation when game session ended
  // to get the latest play history.
  useEffect(() => {
    // NOTE: Playhistory is irrelevant for non-coordinators.
    if (!playHistoryTargetId) return;
    if (ondGamePlayState !== 'ended') return;
    if (!revalidateGamePackPlus) return;

    revalidateGamePackPlus();
  }, [ondGamePlayState, playHistoryTargetId, revalidateGamePackPlus]);

  const myClientType = useMyClientType();
  const coordinator = useParticipant(useGameHostingCoordinator()?.clientId);

  const userIsAlone =
    useNumOfParticipants({
      filters: ['status:connected', 'staff:false'],
    }) === 1;
  const townhallEnabled = useTownhallEnabled();
  const event = useVenueEvent();

  const userHasTeam = !!me?.teamId;
  const hasMe = !!me;

  return useMemo(() => {
    miss();
    if (!hasMe) return null;

    // TODO(drew): this concept should be standardized across the app.
    const gameIsInProgress =
      // NOTE: useVenueMode takes into account stream status, so it is not
      // needed here.
      (isLiveGame && venueMode === VenueMode.Game) ||
      (!isLiveGame && ondGamePlayState !== null);

    const userIsHostClient = ClientTypeUtils.isHost(myClientType);

    // let shadowGamePacksInfo = null;
    // if (
    //   isPairingGame &&
    //   pairing &&
    //   isPairingGamePackLoaded &&
    //   gamePackPlus?.gamePack?.playbackSettings?.gameMakeup !==
    //     EnumsGamePackMakeup.GamePackMakeupMultipleLevels
    // ) {
    //   shadowGamePacksInfo = {
    //     roundId: pairing.roundId,
    //     totalCount: pairing.gamePacks.length,
    //     remainingCount: Math.max(
    //       0,
    //       pairing.gamePacks.length - (pairing.completedGamePackIds?.length ?? 0)
    //     ),
    //     highScore: pairing.score ?? null,
    //   };
    // }

    return {
      isPairingGame,

      shadowGamePacksInfo: null,
      userHasTeam,
      userIsAlone,
      userIsHostClient,
      gameIsInProgress,
      gameOrGamePack: gamePackPlus?.gamePack ?? null,
      gamePackPlus,
      // NOTE(drew): During an OnD/pairs game, `isLiveGame` will only be accurate
      // if the organizer is present in the venue. `useInitGameSession()` sets the
      // `gameSessionStore.session.isLive = false` (through firebase, eventually)
      // when the venue organizer arrives. If `isLive` is undefined, the system
      // assumes it is a live game. Override this when we know it's a pairs game
      // since pairs games can only be played during OnD.
      isOndGame: isPairingGame ? true : !isLiveGame,
      userIsCoordinator,
      coordinator,
      townhallEnabled,
      event,
      featureChecker,
      disabledWidgets,
    };
  }, [
    coordinator,
    event,
    featureChecker,
    gamePackPlus,
    hasMe,
    isLiveGame,
    isPairingGame,
    miss,
    myClientType,
    ondGamePlayState,
    townhallEnabled,
    userHasTeam,
    userIsAlone,
    userIsCoordinator,
    venueMode,
    disabledWidgets,
  ]);
}
