import { useEffect, useState } from 'react';

import { BrowserTimeoutCtrl } from '../../utils/BrowserTimeoutCtrl';
import { type HandlePanelUIAction } from '../RightPanelContext';
import { type Recipient } from './common';
import { useTrackingLastMessageTimestamp } from './Context';
import { Message } from './Message';
import type ChatService from './service';
import { type SCMessageType } from './service';

type MessagePreviewProps = {
  chatService: ChatService;
  messages: SCMessageType[];
  handleReply: (message: SCMessageType) => void;
  handleDelete: (message: SCMessageType) => void;
  handlePanelUIAction?: HandlePanelUIAction;
  privateChannelEnabled: boolean;
  findRecipientByRid: (rid: string) => Recipient | null;
};

export function MessagePreview({
  chatService,
  messages,
  handleReply,
  handleDelete,
  handlePanelUIAction,
  privateChannelEnabled,
  findRecipientByRid,
}: MessagePreviewProps): JSX.Element | null {
  const lastMessage = messages[messages.length - 1];
  const [showPreviewAt, setShowPreviewAt] = useState(0);
  useTrackingLastMessageTimestamp(chatService, messages, () =>
    setShowPreviewAt(Date.now())
  );

  useEffect(() => {
    if (showPreviewAt === 0) return;
    const ctrl = new BrowserTimeoutCtrl();
    ctrl.set(() => {
      setShowPreviewAt(0);
    }, 8000);
    return () => ctrl.clear();
  }, [showPreviewAt]);

  if (showPreviewAt === 0) return null;

  return (
    <ul
      className={`w-full h-full m-0 text-sms relative flex flex-col select-none cursor-pointer`}
    >
      <Message
        message={lastMessage}
        handleReply={handleReply}
        handleDelete={handleDelete}
        handlePanelUIAction={handlePanelUIAction}
        fadeoutMessage={false}
        fadeoutMessageOpacity='opacity-100'
        privateChannelEnabled={privateChannelEnabled}
        findRecipientByRid={findRecipientByRid}
      />
    </ul>
  );
}
