import inviteArrow from '../../assets/img/lobby-invite-arrow.webp';
import { useVenueMode } from '../../hooks/useVenueMode';
import { type Participant } from '../../types';
import { CopyLinkButtonWithPreview } from '../common/CopyButton';
import { useAudienceURLWithPairingInfoFromLocation } from '../common/CopyVenueLink';
import { BannerCreateTeam, useTeamLabel } from './BannerCreateTeam';
import { type LobbyDecisionData } from './useLobbyDecisionData';

export const BannerSecondaryCTA = ({
  decisionData,
  me,
}: {
  decisionData: LobbyDecisionData;
  me: Participant;
}): JSX.Element | null => {
  const venueMode = useVenueMode();
  const teamLabel = useTeamLabel();

  const urlToCopy = useAudienceURLWithPairingInfoFromLocation();

  // The host view never sees any of the secondary CTAs!
  if (!me || decisionData.userIsHostClient) return null;

  if (!decisionData.userHasTeam) {
    return (
      <BannerCreateTeam
        venueMode={venueMode}
        teamLabel={teamLabel}
        variant={'primary'}
      />
    );
  } else if (decisionData.userIsAlone && decisionData.userIsCoordinator) {
    // copy venue
    return (
      <ToolTipsContainer>
        <div className='w-full h-full flex items-center justify-between gap-8'>
          <div>
            <p className='w-full truncate'>
              Welcome to your venue, {me.username}!
            </p>
            <p className='text-primary'>
              Start by inviting others to join you here.
            </p>
          </div>

          <CopyLinkButtonWithPreview urlToCopy={urlToCopy.toString()} />
        </div>
      </ToolTipsContainer>
    );
  } else if (decisionData.isPairingGame) {
    if (decisionData.userIsCoordinator) {
      // "team all here?"
      return (
        <ToolTipsContainer>
          <p className='w-full truncate text-center'>Team All Here?</p>
          <p className='text-tertiary font-bold relative px-2.5 2xl:px-8'>
            Click on the blue “Play Now” button to begin
            <span className='absolute left-full bottom-0 w-max'>
              <img
                src={inviteArrow}
                alt='arrow'
                className='w-20 2xl:w-28'
              ></img>
            </span>
          </p>
        </ToolTipsContainer>
      );
    } else if (decisionData.userIsAlone) {
      const displayName =
        decisionData.coordinator?.firstName ??
        decisionData.coordinator?.username ??
        'organizer';
      // Welcome, please wait for ORGANIZERNAME
      return (
        <ToolTipsContainer>
          <p className='w-full truncate text-center'>
            Welcome to your Pairs Game, {me.username}
          </p>
          <p className='text-tertiary font-bold'>
            Please wait for {displayName} to arrive and start the Game.
          </p>
        </ToolTipsContainer>
      );
    }
  } else {
    return (
      <BannerCreateTeam
        venueMode={venueMode}
        teamLabel={teamLabel}
        variant={decisionData.userHasTeam ? 'secondary' : 'primary'}
      />
    );
  }
  return null;
};

const ToolTipsContainer = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  return (
    <div
      className={`
        relative w-full min-h-30 pl-7.5 pr-5 bg-lp-black-001 rounded-xl
        bg-lobby-tool-tip
        flex flex-col items-center justify-center gap-2
      `}
    >
      {children}
    </div>
  );
};
