import emojiRegex from 'emoji-regex';

import { type Fragment, MentionTrigger, MessageFragmentType } from './common';
import { type SCMessageType, type SCUserType } from './service';

const emojiPattern = emojiRegex();
const urlPattern =
  /(?:(?:(?:https?|ftp):)?\/\/|www\.|ftp\.)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?/i;

const needPlainLargeFragment = (text: string, maxlen = 5): boolean => {
  const matches = text.match(emojiPattern);
  return (
    !!matches &&
    matches.length > 0 &&
    matches.length <= maxlen &&
    matches.join('') === text
  );
};

const buildPlainOrLinkFragments = (text: string): Fragment[] => {
  const fragments = [];
  while (true) {
    const matched = text.match(urlPattern);
    if (!matched || matched.index === undefined) {
      break;
    }
    const t = text.substring(0, matched.index);
    if (t) {
      fragments.push({ type: MessageFragmentType.Plain, fragment: t });
    }
    fragments.push({
      type: MessageFragmentType.Link,
      fragment: text.substr(matched.index, matched[0].length),
    });
    text = text.substring(matched.index + matched[0].length);
  }
  if (text) {
    fragments.push({
      type: MessageFragmentType.Plain,
      fragment: text,
    });
  }
  return fragments;
};

export const buildMessageFragments = (message: SCMessageType): Fragment[] => {
  if (message.local || (message.mentioned_users || []).length === 0) {
    if (needPlainLargeFragment(message.text)) {
      return [{ type: MessageFragmentType.PlainLarge, fragment: message.text }];
    } else {
      return buildPlainOrLinkFragments(message.text);
    }
  } else {
    let text = message.text;
    let fragments: Fragment[] = [];
    while (text) {
      const nextTriggerIdx = text.indexOf(MentionTrigger);
      if (nextTriggerIdx !== -1) {
        let done = false;
        const mentioned_users: SCUserType[] = message.mentioned_users
          ? (message.mentioned_users as SCUserType[])
          : [];
        for (const u of mentioned_users) {
          const index = text.indexOf(`${MentionTrigger}${u.username}`);
          if (index === nextTriggerIdx) {
            const fragment = text.substring(0, index);
            if (fragment) {
              fragments = fragments.concat(buildPlainOrLinkFragments(fragment));
            }
            fragments.push({
              type: MessageFragmentType.Strong,
              fragment: text.substr(index, u.username.length + 1),
            });
            text = text.substring(index + u.username.length + 1);
            done = true;
            break;
          }
        }
        if (!done) {
          const matched = text.match(/@\w*/);
          if (matched) {
            const fragment1 = text.substring(0, matched.index);
            const fragment2 = matched[0];
            if (fragment1) {
              fragments = fragments.concat(
                buildPlainOrLinkFragments(fragment1)
              );
            }
            fragments = fragments.concat(buildPlainOrLinkFragments(fragment2));
            text = text.substring(nextTriggerIdx + fragment2.length);
          }
        }
      } else {
        fragments = fragments.concat(buildPlainOrLinkFragments(text));
        text = '';
      }
    }
    return fragments;
  }
};
