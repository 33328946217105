import { useEffect, useMemo, useState } from 'react';
import { useLatest } from 'react-use';

import { ConnectionStatus } from '@lp-lib/shared-schema';

import { useMyInstance } from '../../hooks/useMyInstance';
import logger from '../../logger/logger';
import { WebRTCUtils } from '../../services/webrtc';
import { isReconnecting } from '../../store/utils';
import { ClientTypeUtils } from '../../types/user';
import { BrowserIntervalCtrl } from '../../utils/BrowserIntervalCtrl';
import { useFirebaseStatus } from '../Firebase';
import { useIsLiveGamePlay } from '../Game/hooks';
import { MicrophoneOffIcon2 } from '../icons/MicrophoneOffIcon2';
import { PoorConnectionIcon } from '../icons/PoorConnectionIcon';
import { Loading } from '../Loading';
import { useHost, useParticipantFlags } from '../Player';
import { useIsStreamSessionAlive } from '../Session';
import { useSelectStageMember } from '../Stage';
import { StreamingActiveMode, useStreamingMode } from '../StreamingTools/hooks';
import { useIsTeamsOnTop } from '../TeamAPI/TeamV1';
import { useMyClientType } from '../Venue/VenuePlaygroundProvider';
import { useIsCoreChannelJoined } from '../WebRTC';

const log = logger.scoped('host-connection');

/**
 * Present this indicator if the host's status becomes disconnected. Ususally
 * it should not happen since the host should see the reconnect/disconnect
 * modal. It seems we(or Firebase) have bugs around the connection status check,
 * use this as a mitigation.
 *
 */
function MaybeDisconnected(): JSX.Element | null {
  const me = useMyInstance();
  const [maybeDisconnected, setMaybeDisconnected] = useState(false);
  const latestFirebaseStatus = useLatest(useFirebaseStatus());
  const streamingMode = useStreamingMode();
  const liveMode = streamingMode === StreamingActiveMode.Live;

  useEffect(() => {
    if (me?.status !== ConnectionStatus.Disconnected) return;
    const ctrl = new BrowserIntervalCtrl();
    ctrl.set(() => {
      if (!isReconnecting(me)) {
        ctrl.clear();
        log.warn('host is disconnected', {
          host: me,
          firebaseStatus: latestFirebaseStatus.current,
        });
        setMaybeDisconnected(true);
      }
    }, 1000);
    return () => {
      ctrl.clear();
      setMaybeDisconnected(false);
    };
  }, [latestFirebaseStatus, me]);

  if (!maybeDisconnected || !liveMode) return null;
  return (
    <div className='flex flex-row bg-lp-red-001 bg-opacity-80 py-2 pr-4 pl-3 rounded-lg items-center justify-center mx-2'>
      Your connection seems broken to the players.
    </div>
  );
}

const HostIndicatorInternal = (props: {
  zIndex: `z-${number}`;
}): JSX.Element | null => {
  const { zIndex } = props;
  const myClientType = useMyClientType();
  const host = useHost();
  const flags = useParticipantFlags(host?.clientId);
  const hostClientId = host?.clientId;
  const hostAudio = flags?.audio;
  const isSessionAlive = useIsStreamSessionAlive();
  const hostOnStage = useSelectStageMember(hostClientId);
  const isLiveGame = useIsLiveGamePlay();
  const stageJoined = useIsCoreChannelJoined('stage');
  const ondJoined = useIsCoreChannelJoined('ond');
  const hostStreamJoined = isLiveGame ? stageJoined : ondJoined;
  const isHost = ClientTypeUtils.isHost(myClientType);
  const teamsOnTop = useIsTeamsOnTop();

  const position = isHost ? 'top-24' : teamsOnTop ? 'bottom-4' : 'top-4';

  const showPoorConnection = useMemo(
    () =>
      WebRTCUtils.CheckNetworkQuality(
        ClientTypeUtils.isHost(myClientType),
        hostOnStage
      ),
    [hostOnStage, myClientType]
  );

  if (!isSessionAlive) return null;

  return (
    <div
      className={`w-full h-auto absolute ${zIndex} ${position} flex flex-row justify-center text-white text-sms`}
    >
      {isLiveGame && showPoorConnection && (
        <div className='flex flex-row bg-black py-2 pr-4 pl-3 rounded-lg items-center justify-center mx-2'>
          <PoorConnectionIcon />
          <span className='ml-2'>
            {ClientTypeUtils.isHost(myClientType)
              ? 'Poor Connection...'
              : 'Host’s connection'}
          </span>
        </div>
      )}
      {isLiveGame && hostAudio === false && (
        <div className='flex flex-row bg-black py-2 pr-4 pl-3 rounded-lg items-center justify-center mx-2'>
          <MicrophoneOffIcon2 />
          <span className='ml-2'>
            {ClientTypeUtils.isHost(myClientType)
              ? 'You are muted'
              : 'Host is muted'}
          </span>
        </div>
      )}
      {!hostStreamJoined && (
        <div className='flex flex-row bg-lp-red-001 bg-opacity-80 py-2 pr-4 pl-3 rounded-lg items-center justify-center mx-2'>
          <Loading text='Host stream is loading...' imgClassName='w-5 h-5' />
        </div>
      )}
      {ClientTypeUtils.isHost(myClientType) && <MaybeDisconnected />}
    </div>
  );
};

const HostIndicator = (props: {
  zIndex: `z-${number}`;
}): JSX.Element | null => {
  const isSessionAlive = useIsStreamSessionAlive();
  if (!isSessionAlive) return null;
  return <HostIndicatorInternal zIndex={props.zIndex} />;
};

export { HostIndicator };
