import { useEffect, useRef, useState } from 'react';
import { usePopperTooltip } from 'react-popper-tooltip';
import { useLatest } from 'react-use';

import { useMyInstance } from '../../hooks/useMyInstance';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import { ClientTypeUtils } from '../../types';
import { TriangleArrowDown } from '../icons/Arrows';
import { useMyClientType } from '../Venue/VenuePlaygroundProvider';
import {
  ChannelType,
  ChatRecipientSelectorDOMId,
  getChannelTypeLabel,
  getChannelTypeTheme,
  type Recipient,
} from './common';
import { buildDisplayName } from './Context';

export type MessageToStyle = 'dropdown' | 'tab';

type MessageToProps = {
  style: MessageToStyle;
  activeRecipient: Recipient | null;
  recipients: Recipient[];
  handleRecipientChange: (rid: string, autoFocus?: boolean) => void;
};

function MessageToDropdown({
  activeRecipient,
  recipients,
  handleRecipientChange,
}: MessageToProps): JSX.Element {
  const channelListRef = useRef<HTMLDivElement | null>(null);
  const [open, setOpen] = useState<boolean>(false);

  useOutsideClick(channelListRef, () => {
    setOpen(false);
  });

  const handleChannelOptionClick = (recipient: Recipient) => {
    handleRecipientChange(recipient.id);
    setOpen(false);
  };

  const handleClick = () => {
    if (recipients.length <= 1) return;
    setOpen(!open);
    if (open) {
      document.getElementById(ChatRecipientSelectorDOMId)?.focus();
    }
  };

  return (
    <div className='flex my-2 h-6.5 text-sms' style={{ lineHeight: '26px' }}>
      <div className='mr-2'>To:</div>
      <div className='w-30 relative' ref={channelListRef}>
        <div
          className='border border-secondary bg-black text-primary rounded-1.5lg cursor-pointer px-2 flex items-center justify-between'
          onClick={handleClick}
          title={activeRecipient?.username}
        >
          <div className='w-20 truncate'>
            {buildDisplayName(activeRecipient)}
          </div>
          <div className='text-white'>
            {recipients.length > 1 && <TriangleArrowDown />}
          </div>
        </div>
        <div
          className={`max-w-45 min-w-30 absolute bottom-0 ${
            open ? 'block' : 'hidden'
          }`}
        >
          <ul
            id={ChatRecipientSelectorDOMId}
            className='list-none bg-black p-0 m-0 w-full max-h-50 overflow-x-hidden overflow-y-auto text-white rounded-xl scrollbar'
          >
            {recipients.map((r: Recipient) => {
              return (
                <li
                  key={r.id}
                  onClick={() => handleChannelOptionClick(r)}
                  title={r.username || ''}
                  className={`${
                    activeRecipient?.id === r.id ? 'text-primary' : 'text-white'
                  } cursor-pointer rounded-xl px-2 truncate hover:bg-lp-black-003`}
                >
                  {buildDisplayName(r)}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      {activeRecipient && (
        <span
          className='ml-2'
          style={{ color: getChannelTypeTheme(activeRecipient.type).label }}
        >
          {getChannelTypeLabel(activeRecipient.type, true)}
        </span>
      )}
    </div>
  );
}

type MessageToTarget = 'everyone' | 'my-team';

function MessageToTab(props: MessageToProps): JSX.Element | null {
  const { recipients, handleRecipientChange, activeRecipient } = props;
  const publicRecipient = recipients.find((r) => r.type === ChannelType.Public);
  const groupRecipient = recipients.find((r) => r.type === ChannelType.Group);
  const [target, setTarget] = useState<MessageToTarget>(
    !!groupRecipient && activeRecipient?.id === groupRecipient.id
      ? 'my-team'
      : 'everyone'
  );
  const latestTarget = useLatest(target);
  const latestRecipients = useLatest(recipients);
  const isHost = ClientTypeUtils.isHost(useMyClientType());
  const me = useMyInstance();

  useEffect(() => {
    if (!me?.teamId) return;
    if (latestTarget.current !== 'my-team') return;
    if (!groupRecipient?.id) return;
    handleRecipientChange(groupRecipient.id, false);
  }, [
    target,
    me?.teamId,
    latestTarget,
    handleRecipientChange,
    latestRecipients,
    groupRecipient?.id,
  ]);

  useEffect(() => {
    const rid =
      target === 'everyone' ? publicRecipient?.id : groupRecipient?.id;
    if (!rid) return;
    handleRecipientChange(rid);
  }, [groupRecipient?.id, handleRecipientChange, publicRecipient?.id, target]);

  const messageTo = (target: MessageToTarget) => {
    setTarget(target);
  };

  const { getTooltipProps, setTooltipRef, setTriggerRef, visible } =
    usePopperTooltip({
      placement: 'top',
      trigger: 'hover',
    });

  return (
    <div className='flex items-center my-2 h-6 text-2xs'>
      <div className='mr-2'>To:</div>
      <div
        ref={setTriggerRef}
        className='bg-black w-full h-full flex items-center justify-center border border-white-001 rounded-xl relative'
      >
        {isHost ? (
          <button className='btn w-full h-full font-bold' disabled>
            Everyone
          </button>
        ) : (
          <>
            <div
              className={`absolute message-to-tab-btn w-1/2 h-[calc(100%-2px)] transition-position rounded-xl ${
                target === 'my-team'
                  ? 'bg-gradient-to-tr from-yellow-start to-yellow-end left-0.5'
                  : 'bg-secondary left-[calc(50%-2px)]'
              }`}
            ></div>
            <button
              className={`btn w-1/2 h-full absolute left-0 ${
                target === 'my-team' ? 'font-bold' : ''
              }`}
              onClick={() => messageTo('my-team')}
            >
              My Team
            </button>
            <button
              className={`btn w-1/2 h-full absolute right-0 ${
                target === 'everyone' ? 'font-bold' : ''
              }`}
              onClick={() => messageTo('everyone')}
            >
              Everyone
            </button>
            {visible && (
              <div
                ref={setTooltipRef}
                {...getTooltipProps({
                  className: `w-auto h-auto rounded-md text-white text-2xs px-2 py-1 bg-black bg-opacity-80 whitespace-nowrap border border-secondary`,
                })}
              >
                Who will receive your messages
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export function MessageTo(props: MessageToProps): JSX.Element {
  if (props.style === 'tab') return <MessageToTab {...props} />;
  return <MessageToDropdown {...props} />;
}
