import { useRef } from 'react';

import { ProfileIndex } from '@lp-lib/crowd-frames-schema';

import { type Participant } from '../../types/user';
import {
  ContextMenuScope,
  useContextMenuContext,
} from '../ContextMenu/Context';
import { CrowdFramesAvatar } from '../CrowdFrames';
import {
  useMyInstance,
  useParticipantFlag,
  useParticipantsAsArray,
} from '../Player';
import {
  type RightPanelTabProps,
  RightPanelTabState,
} from '../RightPanelContext';

function ParticipantPlate(props: {
  participant: Participant;
  parentOccluding: HTMLElement | null;
}) {
  const onStage = useParticipantFlag(props.participant.clientId, 'onStage');
  const contextMenuCtx = useContextMenuContext();

  const handleToggleContextMenu = (event: React.MouseEvent) => {
    contextMenuCtx.setOptions({
      x: event.pageX,
      y: event.pageY,
      clientId: props.participant.clientId,
      insideRightPanel: true,
      scope: ContextMenuScope.Teams,
    });
  };

  return (
    <div className='flex flex-row items-center w-full h-9 pl-2.5 pr-2.5 relative'>
      <div
        className='flex flex-row items-center relative cursor-pointer space-x-2 w-full'
        onClick={handleToggleContextMenu}
      >
        <div className='relative flex-grow-0 flex-shrink-0 w-9 h-9'>
          <CrowdFramesAvatar
            participant={props.participant}
            profileIndex={ProfileIndex.wh36x36fps8}
            enablePointerEvents={false}
            throttleRegistration={true}
          />
        </div>
        <div className={`flex flex-col overflow-hidden`}>
          {onStage && (
            <div className='text-3xs text-red-002 font-bold'>ON STAGE</div>
          )}
          <div className='text-white text-xs truncate'>
            {props.participant.username}
          </div>
        </div>
      </div>
    </div>
  );
}

function PanelInternal(_props: RightPanelTabProps): JSX.Element | null {
  const parentOccluding = useRef<HTMLDivElement | null>(null);
  const me = useMyInstance();
  const participants = useParticipantsAsArray({
    filters: ['staff:false', 'host:false', 'status:connected', 'team:true'],
    sorts: ['username:asc'],
  });
  const others = participants.filter((p) => p.clientId !== me?.clientId);

  return (
    <div
      ref={parentOccluding}
      className='w-full h-full flex flex-col space-y-0.5'
    >
      <div className='scrollbar overflow-y-auto grid grid-cols-2 gap-y-2.5'>
        {me && (
          <ParticipantPlate
            participant={me}
            parentOccluding={parentOccluding.current}
          />
        )}
        {others.map((p) => (
          <ParticipantPlate
            key={p.clientId}
            participant={p}
            parentOccluding={parentOccluding.current}
          />
        ))}
      </div>
    </div>
  );
}

export function TeamlessPanel(props: RightPanelTabProps): JSX.Element | null {
  if (props.tab !== RightPanelTabState.People) return null;
  return <PanelInternal {...props} />;
}
