import { type SVGProps } from 'react';

const ChatGlobeIcon = (props: SVGProps<SVGElement>): JSX.Element => {
  return (
    <svg
      className={props.className || 'w-3 h-3 fill-current'}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 12 12'
    >
      <path
        fill='#fff'
        fillRule='evenodd'
        d='M5.986 0A5.982 5.982 0 000 5.986C0 9.294 2.675 12 5.986 12 9.296 12 12 9.296 12 5.986S9.294 0 5.986 0zm1.99 2.997c-.342-.804-.838-1.497-1.505-1.925v4.43h2.077a7.692 7.692 0 00-.571-2.505zm-3.953 0c.338-.793.826-1.478 1.478-1.907V5.5h-2.05c.05-.812.232-1.705.572-2.504zm5.495 2.504c-.077-1.334-.468-2.84-1.276-3.997a4.986 4.986 0 012.745 3.997h-1.47zm-7.036 0H.985a5.025 5.025 0 012.758-4c-.794 1.157-1.184 2.664-1.26 4zM3.744 10.5A5.033 5.033 0 01.984 6.47h1.498c.077 1.338.468 2.865 1.262 4.028zm.28-1.51a7.802 7.802 0 01-.572-2.518h2.05v4.44c-.653-.43-1.14-1.122-1.479-1.921zm2.447 1.94V6.47h2.077a7.802 7.802 0 01-.571 2.519c-.343.81-.84 1.51-1.506 1.938zm1.77-.432c.81-1.163 1.2-2.69 1.277-4.026h1.47a4.994 4.994 0 01-2.748 4.026z'
        clipRule='evenodd'
      />
    </svg>
  );
};

export { ChatGlobeIcon };
