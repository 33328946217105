import { ChatUnreadMessagesCounterDOMId } from './common';

const UnreadMessageCounter = (): JSX.Element => {
  // The useSelector with Redux would cause too many unnecessary re-renders,
  // using DOM solution here for the workaround,we can revert the implementation
  // once we solved the performance issue.
  return (
    <span
      className='rounded-full bg-lp-red-001 text-white text-4xs w-[fit-content] min-w-4 px-1 h-4 hidden items-center justify-center'
      id={ChatUnreadMessagesCounterDOMId}
    />
  );
};

export { UnreadMessageCounter };
