import copy from 'copy-to-clipboard';
import { useCallback, useEffect, useRef } from 'react';

import { ConnectionStatus } from '@lp-lib/shared-schema';

import {
  getFeatureQueryParam,
  getFeatureQueryParamArray,
  useFeatureQueryParam,
} from '../../hooks/useFeatureQueryParam';
import { useInstance } from '../../hooks/useInstance';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import { useVenueMode } from '../../hooks/useVenueMode';
import { useSendTeamInvite } from '../../store/teamInviteHooks';
import { ClientTypeUtils, type Participant } from '../../types/user';
import { VenueMode } from '../../types/venue';
import { useChatSharedContext } from '../Chat/SharedContext';
import { useGameHostingCoordinator } from '../Game/GameHostingProvider';
import {
  useAmICohost,
  useMyTeamId,
  useParticipantByClientId,
  useParticipantFlags,
} from '../Player';
import { useRemoteRefresh } from '../RemoteRefresh/RemoteRefreshProvider';
import { useIsStreamSessionAlive } from '../Session';
import {
  StageMode,
  useBringTeamOnStageWithConfirmation,
  useIsStageEmpty,
  useIsStageFull,
  useSelectIsH2HAvailable,
  useStageControlAPI,
  useStageMode,
} from '../Stage';
import {
  useAssignNewTeamCaptain,
  useMarkAsAway,
  useTeam,
} from '../TeamAPI/TeamV1';
import {
  useMyClientId,
  useMyClientType,
} from '../Venue/VenuePlaygroundProvider';
import { ContextMenuScope, useContextMenuContext } from './Context';

const debugToolState = getFeatureQueryParamArray('debug-tools');

interface ContextMenuItem {
  key: string;
  text: string;
  color?: string;
  disabled?: boolean;
  handleClick?: (event: React.MouseEvent, participant: Participant) => void;
}

interface ContextMenuProps {
  className?: string;
}

const getColor = (item: ContextMenuItem): string => {
  if (item.disabled) {
    return 'text-secondary';
  } else {
    return item.color ? item.color : 'text-white';
  }
};

const ContextMenuInternal = ({ className }: ContextMenuProps): JSX.Element => {
  const myClientType = useMyClientType();
  const myClientId = useMyClientId();
  const isSessionAlive = useIsStreamSessionAlive();
  const isStageFull = useIsStageFull();
  const stageMode = useStageMode();
  const isStageEmpty = useIsStageEmpty();
  const isH2HAvailable = useSelectIsH2HAvailable();
  const { options: contextMenuOptions, setOptions } = useContextMenuContext();
  const { chatInited, setChatToClientId } = useChatSharedContext();
  const participant = useParticipantByClientId(contextMenuOptions?.clientId);
  const participantFlags = useParticipantFlags(contextMenuOptions?.clientId);
  const participantTeam = useTeam(participant?.teamId);
  const myTeamId = useMyTeamId();
  const sendTeamInvite = useSendTeamInvite();
  const h2hEnabled = getFeatureQueryParam('h2h');
  const ref = useRef<HTMLDivElement>(null);
  const venueMode = useVenueMode();
  const stageControl = useStageControlAPI();
  const bringTeamOnStage = useBringTeamOnStageWithConfirmation();
  const privateChatEnabled = useInstance(() =>
    getFeatureQueryParam('chat-private-channel')
  );
  const assignNewCaptain = useAssignNewTeamCaptain();
  const markAsAway = useMarkAsAway();
  const markAsAwayEnabled = useFeatureQueryParam('mark-as-away');
  const remoteRestart = useRemoteRefresh();
  const coordinator = useGameHostingCoordinator();
  const amICohost = useAmICohost();

  // we don't use this anymore
  const canInviteToTeam = false;

  const canHostMakeCaptain =
    (ClientTypeUtils.isHost(myClientType) || amICohost) &&
    participant?.clientId !== participantTeam?.captainScribe &&
    participant?.teamId &&
    venueMode !== VenueMode.Lobby &&
    contextMenuOptions &&
    contextMenuOptions.scope !== ContextMenuScope.Chat;

  useEffect(() => {
    if (!ref.current) {
      return;
    }
    if (contextMenuOptions) {
      // dont allow off left edge
      let left = Math.max(
        contextMenuOptions.x - ref.current.offsetWidth / 2,
        0
      );
      // adjust to keep off right edge
      const rightOffset =
        -1 *
        Math.max(
          contextMenuOptions.x +
            ref.current.offsetWidth / 2 -
            window.innerWidth,
          0
        );
      // if both clipped, do nothing, otherwise adjust
      if (!(left === 0 && rightOffset !== 0)) {
        left += rightOffset;
      }

      // place-above by default, switch to place-below if not enough space.
      let top = contextMenuOptions.y - ref.current.offsetHeight - 10;
      if (top < 0) {
        // keep from going off bottom.
        const bottomOffset = Math.max(
          contextMenuOptions.y +
            ref.current.offsetHeight +
            10 -
            window.innerHeight,
          0
        );
        top = contextMenuOptions.y + 10 - bottomOffset;
      }

      ref.current.style.left = `${left}px`;
      ref.current.style.top = `${top}px`;
    } else {
      ref.current.style.left = '0px';
      ref.current.style.top = '0px';
    }
    ref.current.classList.remove('invisible'); // show once ready
  }, [contextMenuOptions]);

  const handleOnStageClick = async () => {
    if (!isSessionAlive || !participant?.clientId) return;
    if (participantFlags?.onStage) {
      await stageControl.leave(participant.clientId);
    } else {
      await stageControl.join(participant.clientId);
    }
  };

  const handleHeadToHeadClick = async () => {
    if (!isSessionAlive || !participant?.clientId) return;
    if (participantFlags?.onStage) {
      await stageControl.leave(participant.clientId);
    } else {
      await stageControl.join(participant.clientId, StageMode.H2H);
    }
  };

  const handleHostMakeTeamCaptain = () => {
    if (!participant?.clientId) return;
    if (participant?.teamId)
      assignNewCaptain(participant?.teamId, participant?.clientId);
  };

  const handlePrivateMessageClick = () => {
    if (!participant?.clientId) return;
    setChatToClientId(participant.clientId);
  };

  const handleInviteClick = () => {
    if (!canInviteToTeam) return;
    if (!myTeamId) return;
    if (!participant?.clientId) return;
    sendTeamInvite(myClientId, {
      teamId: myTeamId,
      inviteeClientId: participant?.clientId,
      invitedAt: Date.now(),
    });
  };

  const handleBringTeamOnStageClick = async () => {
    if (!participant?.teamId) return;
    await bringTeamOnStage(participant.teamId);
  };

  const handleCopyUserData = useCallback(() => {
    copy(JSON.stringify(participant, null, '\t'));
  }, [participant]);

  const handleMarkAsAway = useCallback(async () => {
    if (!participant?.clientId || !participant?.teamId) return;
    await markAsAway(participant.clientId, participant.teamId, {
      clientId: myClientId,
      reason: 'host',
    });
  }, [markAsAway, myClientId, participant?.clientId, participant?.teamId]);

  const handleForceRefresh = useCallback(async () => {
    if (!participant?.clientId) return;
    await remoteRestart(participant.clientId);
  }, [participant?.clientId, remoteRestart]);

  const meunItems: ContextMenuItem[] = [];
  if (participant && ClientTypeUtils.isHost(myClientType) && isSessionAlive) {
    h2hEnabled &&
      meunItems.push({
        key: 'action-head-to-head',
        text:
          participantFlags?.onStage && stageMode === StageMode.H2H
            ? 'Remove from Head-to-Head'
            : 'Head-to-Head',
        handleClick: handleHeadToHeadClick,
        disabled:
          Boolean(participant?.away) ||
          (isStageFull && !participantFlags?.onStage) ||
          (stageMode !== StageMode.H2H && !isStageEmpty) ||
          !isH2HAvailable,
      });
    meunItems.push({
      key: 'action-bring-on-stage',
      text:
        participantFlags?.onStage && stageMode === StageMode.BOS
          ? 'Remove from Stage'
          : 'Bring on Stage',
      handleClick: handleOnStageClick,
      disabled:
        Boolean(participant?.away) ||
        (isStageFull && !participantFlags?.onStage) ||
        (stageMode !== StageMode.BOS && !isStageEmpty),
    });
    meunItems.push({
      key: 'action-bring-team-on-stage',
      text: 'Bring Team on Stage',
      handleClick: handleBringTeamOnStageClick,
      // This option is purposefully still enabled even if the current
      // participant is on stage.
      disabled:
        !participant?.teamId ||
        isStageFull ||
        (stageMode !== StageMode.BOS && !isStageEmpty),
    });
  }

  if (participant && isSessionAlive && coordinator?.clientId === myClientId) {
    if (markAsAwayEnabled && participant.clientId !== coordinator.clientId) {
      meunItems.push({
        key: 'mark-as-away',
        text: 'Mark as Away',
        handleClick: handleMarkAsAway,
        disabled: Boolean(
          !participant?.teamId || participant?.away || participantFlags?.onStage
        ),
      });
    }
  }

  if (canHostMakeCaptain) {
    meunItems.push({
      key: 'action-make-captain',
      text: 'Make Team Captain',
      handleClick: handleHostMakeTeamCaptain,
    });
  }

  if (
    privateChatEnabled &&
    participant &&
    participant.clientId !== myClientId
  ) {
    meunItems.push({
      key: 'action-private-message',
      text: 'Private Message',
      handleClick: handlePrivateMessageClick,
      disabled: !chatInited,
    });
  }
  if (canInviteToTeam) {
    meunItems.push({
      key: 'action-team-invite',
      text: 'Invite to Team',
      handleClick: handleInviteClick,
    });
  }

  if (debugToolState !== 'disabled' && participant) {
    meunItems.push({
      key: 'action-copy-user-data',
      text: 'Copy User Data',
      handleClick: handleCopyUserData,
    });
    meunItems.push({
      key: 'action-force-refresh',
      text: 'Force Refresh',
      handleClick: handleForceRefresh,
    });
  }

  const handleClose = () => {
    setOptions(null);
  };

  const handleClick = (
    event: React.MouseEvent,
    item: ContextMenuItem
  ): void => {
    if (participant && !item.disabled && item.handleClick) {
      item.handleClick(event, participant);
    }
    handleClose();
  };
  useOutsideClick(ref, handleClose);

  const showContextMenu =
    meunItems.length > 0 &&
    contextMenuOptions &&
    participant?.clientId &&
    participant.status === ConnectionStatus.Connected;

  return (
    <div
      ref={ref}
      className={`${className} bg-lp-black-004 rounded-xl text-3xs z-45 absolute invisible ${
        showContextMenu ? 'block' : 'hidden'
      }`}
      data-rightpanel-inside={!!contextMenuOptions?.insideRightPanel}
    >
      <ul className='rounded-xl after:tooltipArrowCenterBottom p-0.5'>
        {meunItems.map((item) => (
          <li
            className={`flex items-center justify-center px-2 py-2.5 rounded-xl cursor-pointer ${
              item.disabled ? '' : 'hover:bg-lp-black-003'
            } whitespace-nowrap ${getColor(item)}`}
            key={item.key}
            onClick={(event) => handleClick(event, item)}
          >
            {item.text}
          </li>
        ))}
      </ul>
    </div>
  );
};

export function ContextMenu(props: ContextMenuProps) {
  const { options } = useContextMenuContext();
  if (!options) return null;
  return <ContextMenuInternal {...props} />;
}
