import {
  type AnalyticsBrowser,
  type EventProperties,
} from '@segment/analytics-next';

export class LobbyAnalytics {
  private dedupSet = new Set<string>();
  constructor(readonly analytics: AnalyticsBrowser) {}

  trackGuestUserConversionBannerViewed(
    key: string,
    properties?: EventProperties
  ) {
    if (this.dedupSet.has(key)) return;
    this.analytics.track('Guest User Conversion Banner Viewed', properties);
    this.dedupSet.add(key);
  }

  trackGuestUserWizardOpened(properties?: EventProperties) {
    this.analytics.track('Guest User Wizard Opened', properties);
  }

  trackGuestUserConverted(properties?: EventProperties) {
    this.analytics.track('Guest User Converted', properties);
  }
}
