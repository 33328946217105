export function DeviceBlockedArrowIcon(): JSX.Element {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='30'
      height='52'
      fill='none'
      viewBox='0 0 30 52'
    >
      <path
        fill='#fff'
        d='M16.414.586a2 2 0 00-2.828 0L.858 13.314a2 2 0 102.828 2.828L15 4.828l11.314 11.314a2 2 0 102.828-2.828L16.414.586zM17 52V2h-4v50h4z'
      ></path>
    </svg>
  );
}
