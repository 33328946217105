import React, { type ReactNode, useCallback } from 'react';

import { EnumsPageName } from '@lp-lib/api-service-client/public';

import { useLoadGame } from '../../../hooks/useLoadGame';
import { EmbeddedPublicLibrary } from '../../../pages/GamePack';
import {
  GamePackContextProvider,
  useGamePackContext,
} from '../../../pages/GamePack/Context';
import { GamePackCRUDUtility } from '../../../pages/GamePack/CRUDUtility';
import { GamePackPicker } from '../../../pages/GamePack/Picker';
import {
  MinigameContextProvider,
  useMinigameContext,
} from '../../../pages/Minigame/Context';
import { GameCRUDUtility } from '../../../pages/Minigame/CRUDUtility';
import { GamePicker } from '../../../pages/Minigame/Picker';
import { type Game, type GamePack } from '../../../types/game';
import { XIcon } from '../../icons/XIcon';
import { useCloseGameLibrary, useGameLibrary } from './GameLibraryContext';
import { GameLibraryHeader } from './GameLibraryHeader';

export const GameLibrary = (props: { children?: ReactNode }): JSX.Element => {
  const loadGame = useLoadGame();
  const library = useGameLibrary();
  const closeGameLibrary = useCloseGameLibrary();

  const loadGameAndClose = useCallback(
    async (pack: Game | GamePack) => {
      if (!library) return false;
      if (library.canLoad) {
        if (!(await library.canLoad())) {
          return false;
        }
      }
      let ret: boolean;
      if (library.onGamePackClick && pack.type === 'gamePack') {
        ret = await library.onGamePackClick(pack);
      } else {
        await loadGame(pack);
        ret = true;
      }

      if (!ret) return false;
      closeGameLibrary();
      return true;
    },
    [closeGameLibrary, library, loadGame]
  );

  return (
    <>
      <MinigameContextProvider embed>
        {library?.type === 'game' && (
          <MinigameLibrary onGameClick={loadGameAndClose} />
        )}

        <GameCRUDUtility />
      </MinigameContextProvider>

      <GamePackContextProvider
        embed
        pageType={library?.type === 'gamePack' ? 'default' : 'public'}
        handleLoad={loadGameAndClose}
        noOnboardingTasks={library?.type === 'bottomPublicLibrary'}
      >
        {library?.type === 'gamePack' && (
          <GamePackLibrary onGamePackClick={loadGameAndClose} />
        )}

        {(library?.type === 'publicLibrary' ||
          library?.type === 'bottomPublicLibrary') && (
          <BottomPublicLibrary onItemClick={loadGameAndClose} />
        )}

        {library?.type === 'zoom' && (
          <BottomPublicLibrary
            pageName={EnumsPageName.PageNameZoom}
            onItemClick={loadGameAndClose}
          />
        )}

        <GamePackCRUDUtility />

        {props.children}
      </GamePackContextProvider>
    </>
  );
};

export const MinigameLibrary = (props: {
  onGameClick: (item: Game) => void;
}): JSX.Element => {
  const ctx = useMinigameContext();

  return (
    <LibraryLayout
      header={<GameLibraryHeader ctx={ctx} menus search avatar />}
      content={<GamePicker onItemClick={props.onGameClick} />}
    />
  );
};

export const GamePackLibrary = (props: {
  onGamePackClick: (item: GamePack) => void;
}): JSX.Element => {
  const ctx = useGamePackContext();

  return (
    <LibraryLayout
      header={<GameLibraryHeader ctx={ctx} menus search avatar />}
      content={<GamePackPicker onItemClick={props.onGamePackClick} />}
    />
  );
};

export const PublicLibrary = (props: {
  onItemClick?: (item: GamePack) => void;
}): JSX.Element => {
  const ctx = useGamePackContext();

  return (
    <LibraryLayout
      header={<GameLibraryHeader ctx={ctx} />}
      content={
        <GamePackPicker
          scope={EnumsPageName.PageNamePublicGamePacks}
          primeOnly
          onItemClick={props.onItemClick}
        />
      }
    />
  );
};

export const BottomPublicLibrary = (props: {
  pageName?: EnumsPageName;
  onItemClick: (item: GamePack) => void;
}): JSX.Element => {
  const closeGameLibrary = useCloseGameLibrary();

  return (
    <div className='z-50 absolute bottom-0 left-0 top-30 lg:top-35 xl:top-45 right-0 bg-modal'>
      <button
        type='button'
        className='
          absolute top-0 right-0 z-50 icon-btn-no-shadow bg-transparent w-10 h-10 text-white rounded-none
        '
        onClick={closeGameLibrary}
      >
        <XIcon />
      </button>

      <EmbeddedPublicLibrary
        pageName={props.pageName}
        onGamePackClick={props.onItemClick}
      />
    </div>
  );
};

export const LibraryLayout = (props: {
  header: React.ReactNode;
  content: React.ReactNode;
}): JSX.Element => {
  return (
    <div className='w-full h-full bg-black flex flex-col z-50 absolute top-0 left-0'>
      {props.header}
      <div className='flex-1 overflow-y-auto scrollbar bg-game-library bg-w-full bg-no-repeat bg-local'>
        {props.content}
      </div>
    </div>
  );
};
