import React, { useEffect, useLayoutEffect, useRef } from 'react';
import { useToggle } from 'react-use';

import { type HandlePanelUIAction } from '../RightPanelContext';
import {
  ChatMessageListDOMId,
  ChatMode,
  isEditingActive,
  MessageFadeoutMS,
  type Recipient,
} from './common';
import {
  useChatMode,
  useChatService,
  useTrackingLastMessageTimestamp,
} from './Context';
import { Message } from './Message';
import { type SCMessageType } from './service';

interface MessageListProp {
  messageListElRef: React.MutableRefObject<HTMLDivElement | null>;
  messages: SCMessageType[];
  hasNewMessagesAfterStopScrolling: boolean;
  handleReply: (message: SCMessageType) => void;
  handleDelete: (message: SCMessageType) => void;
  handlePanelUIAction?: HandlePanelUIAction;
  fadeoutMessage: boolean;
  handleToggleFadeoutMessage: (val: boolean) => void;
  privateChannelEnabled: boolean;
  findRecipientByRid: (rid: string) => Recipient | null;
}

const MessageList = ({
  messageListElRef,
  messages,
  hasNewMessagesAfterStopScrolling,
  handleReply,
  handleDelete,
  handlePanelUIAction,
  fadeoutMessage,
  handleToggleFadeoutMessage,
  privateChannelEnabled,
  findRecipientByRid,
}: MessageListProp) => {
  const chatService = useChatService();
  const chatMode = useChatMode();
  const [isHover, setIsHover] = useToggle(false);
  const wheelTimerIdRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const fadeoutTimerIdRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const skipToBottomRef = useRef<HTMLDivElement | null>(null);
  const fadeoutStubRef = useRef<HTMLDivElement | null>(null);
  const lastMessageId = messages[messages.length - 1]?.id;

  useTrackingLastMessageTimestamp(chatService, messages);

  /**
   * Since the fadeout transition is applied on each message,
   * it's hard to add one listener to tell when the transition compeleted.
   * Here we introduced a stub dom element, which will be applied the same
   * fadeout transition, so we can listen the `transitionend` event on this
   * element and scroll the list to bottom once the transition completed.
   */
  useEffect(() => {
    if (chatMode !== ChatMode.Preview) return;
    const transitionEnded = () => {
      if (!fadeoutStubRef.current?.classList.contains('opacity-0')) {
        return;
      }
      chatService.scheduleScrollToBottom(0);
    };
    fadeoutStubRef.current?.addEventListener('transitionend', transitionEnded);
    const stubRef = fadeoutStubRef.current;
    return () => {
      stubRef?.removeEventListener('transitionend', transitionEnded);
    };
  }, [chatMode, chatService]);

  useEffect(() => {
    if (chatMode !== ChatMode.Preview) return;
    if (!isHover) {
      if (isEditingActive()) return;
      if (wheelTimerIdRef.current) {
        clearTimeout(wheelTimerIdRef.current);
      }
      wheelTimerIdRef.current = setTimeout(() => {
        handleToggleFadeoutMessage(true);
      }, 500);
    } else {
      if (wheelTimerIdRef.current) {
        clearTimeout(wheelTimerIdRef.current);
      }
      handleToggleFadeoutMessage(false);
    }
  }, [chatService, handleToggleFadeoutMessage, isHover, chatMode]);

  /**
   * Fadeout the `skip to bottom` button and the stub div
   */
  useEffect(() => {
    if (fadeoutMessage) {
      if (fadeoutTimerIdRef.current) {
        clearTimeout(fadeoutTimerIdRef.current);
      }
      fadeoutTimerIdRef.current = setTimeout(() => {
        skipToBottomRef.current?.classList.add('opacity-30');
        fadeoutStubRef.current?.classList.add('opacity-0');
      }, MessageFadeoutMS);
    } else {
      if (fadeoutTimerIdRef.current) {
        clearTimeout(fadeoutTimerIdRef.current);
      }
      skipToBottomRef.current?.classList.remove('opacity-30');
      fadeoutStubRef.current?.classList.remove('opacity-0');
    }
  }, [fadeoutMessage]);

  useLayoutEffect(() => {
    if (!chatService.isUserScrolling) {
      chatService.scheduleScrollToBottom(0, false);
    }
  }, [chatService, lastMessageId]);

  const checkIsUserScrolling = () => {
    if (messageListElRef.current) {
      const el = messageListElRef.current;
      if (el.scrollHeight - el.clientHeight - el.scrollTop > 50) {
        chatService.isUserScrolling = true;
      } else {
        chatService.isUserScrolling = false;
      }
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (
      e.which === 33 || // page up
      e.which === 34 || // page dn
      e.which === 32 || // space bar
      e.which === 38 || // up
      e.which === 40 || // down
      (e.ctrlKey && e.which === 36) || // ctrl + home
      (e.ctrlKey && e.which === 35) // ctrl + end
    ) {
      checkIsUserScrolling();
    }
  };

  const handleWheel = () => {
    checkIsUserScrolling();
    handleToggleFadeoutMessage(false);
    if (wheelTimerIdRef.current) {
      clearTimeout(wheelTimerIdRef.current);
    }
    chatService.cancelScrollToBottom();
  };

  const handleTouchMove = () => {
    checkIsUserScrolling();
  };

  const handleScroll = () => {
    // checkIsUserScrolling();
  };

  const handleSkipToBottom = () => {
    chatService.isUserScrolling = false;
    chatService.scheduleScrollToBottom(0);
  };

  const handlenPointerEnter = () => {
    if (chatMode !== ChatMode.Preview) return;
    setIsHover(true);
  };

  const handlenPointerLeave = () => {
    if (chatMode !== ChatMode.Preview) return;
    setIsHover(false);
  };

  return (
    <div
      id={ChatMessageListDOMId}
      ref={messageListElRef}
      onWheel={handleWheel}
      onKeyDown={handleKeyDown}
      onScroll={handleScroll}
      onTouchMove={handleTouchMove}
      onPointerEnter={handlenPointerEnter}
      onPointerLeave={handlenPointerLeave}
      className={`flex flex-row items-center justify-center flex-auto flex-grow overflow-y-auto overflow-x-hidden ${
        chatMode === ChatMode.Preview
          ? 'min-h-0 max-h-50 chat-list-mask-image scrollbar-no-thumb'
          : 'h-full scrollbar'
      }`}
    >
      {hasNewMessagesAfterStopScrolling && (
        <div
          ref={skipToBottomRef}
          className={`absolute z-4 transition-opacity duration-1000 ${
            chatMode === ChatMode.Preview ? 'bottom-[92px]' : 'bottom-[82px]'
          }`}
        >
          <button
            type='button'
            className='btn-primary text-2xs py-1 px-2'
            onClick={handleSkipToBottom}
          >
            Skip to latest message
          </button>
        </div>
      )}
      <div ref={fadeoutStubRef} className='transition-opacity duration-1000' />
      <ul
        className={`w-full h-full ${
          chatMode === ChatMode.Preview ? 'py-0' : 'px-2.5'
        } m-0 text-sms relative flex flex-col`}
      >
        {messages.map((m) => {
          return (
            <Message
              key={m.id}
              message={m}
              handleReply={handleReply}
              handleDelete={handleDelete}
              handlePanelUIAction={handlePanelUIAction}
              fadeoutMessage={fadeoutMessage}
              fadeoutMessageOpacity='opacity-30'
              privateChannelEnabled={privateChannelEnabled}
              findRecipientByRid={findRecipientByRid}
            />
          );
        })}
      </ul>
    </div>
  );
};

const Memoed = React.memo(MessageList);
export { Memoed as MessageList };
